import {CounterInterface} from '../../store/types/CustomerAgreementCondition';
import {roundUp} from "../../utils/roundUp";
import i18n from "../../services/i18n";

export type BundleRowProps = {
    product: CounterInterface;
    lastRow: boolean
};


const SUPPORTED_TIME_UNITS = [
    'second', 'seconds', 'minute', 'minutes', 'hours', 'hour', 'min', 'sec', 'h'
]


const translationMapping = {

    'second': 'seconds',
    'seconds': 'seconds',
    'sec': 'seconds',
    'minute': 'minutes',
    'minutes': 'minutes',
    'min': 'minutes',
    'hours': 'hours',
    'hour': 'hours',
    'h': 'hours',
}

const nextTimeUnitMapping = {
    'minute': 'seconds',
    'minutes': 'seconds',
    'min': 'seconds',
    'hours': 'minutes',
    'hour': 'minutes',
    'h': 'minutes',
    'second': 'ms',
    'seconds': 'ms',
    'sec': 'ms',
}


export const convertToMinutesAndSeconds = (
    value: number, unitType = 'minutes', ratio = 60, translation?: string
) => {

    if (!SUPPORTED_TIME_UNITS.includes(unitType)) {
        return roundUp(value, 2) + ' ' + translation ?? unitType
    }

    // @ts-ignore
    const translationValue = translationMapping[unitType] ?
        // @ts-ignore
        i18n.t<string>('units:time.' + translationMapping[unitType]) : translation ?? unitType

    // @ts-ignore
    const nextUnitTranslation = i18n.t<string>('units:time.' + nextTimeUnitMapping[unitType], 'sec')


    const totalSeconds = value * ratio;

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.round(totalSeconds % 60);

    const formattedSeconds = seconds ? seconds < 10 ? `0${seconds}` : seconds : 0;

    if (minutes && seconds) {
        return `${minutes} ${translationValue} ${formattedSeconds} ${nextUnitTranslation}`;
    }
    if (!seconds) {
        return `${minutes} ${translationValue}`;
    } else {
        return `${formattedSeconds} ${nextUnitTranslation}`;
    }
}

export const getLabel = (product: CounterInterface) => {
    const {i_vd_type, usage_period} = product

    let output = ''

    if (i_vd_type == 1) {
        output = i18n.t<string>(`screens:dashboard.discount`)
    }

    if (i_vd_type == 2 && usage_period) {
        output = i18n.t<string>('screens:dashboard.quota')
    }

    if (i_vd_type == 2 && !usage_period) {
        output = i18n.t<string>('screens:dashboard.wallet')
    }

    if (product.peak_level == '1' || product.peak_level == '2' || product.peak_level == '1,2') {
        output = i18n.t<string>('screens:dashboard.off-peak') + output
    } else if (product.peak_level == '0' || product.peak_level == '0,1') {
        output = i18n.t<string>('screens:dashboard.peak') + output
    }

    return output
}
