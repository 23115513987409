import {createAction} from 'typesafe-actions';
import {CounterInterface, CustomerAgreementCondition} from '../../types/CustomerAgreementCondition';

export const getDashboardData = createAction('GET_DASHBOARD_DATA')<undefined>();

export const setRegisteredExtensions = createAction(
    'SET_REGISTERED_EXTENSIONS',
)<number | undefined>();
export const setUnregisteredExtensions = createAction(
    'SET_UNREGISTERED_EXTENSIONS',
)<number | undefined>();
export const setDisabledExtensions = createAction('SET_DISABLED_EXTENSIONS')<
    number | undefined
>();
export const setCallsConnected = createAction('SET_CALLS_CONNECTED')<
    number | undefined
>();

export const setCallsFromLastDays = createAction('SET_CALLS_FROM_LAST_DAYS')<
    { date: string; value: number }[] | undefined
>();

export const setAllDidNumbers = createAction('SET_ALL_DID_NUMBER')<
    number | undefined
>();

export const accessDeniedToDidNumbers = createAction('ACCESS_DENIED_TO_DID_NUMBERS')<
    boolean
>();

export const setInUseDidNumbers = createAction('SET_IN_USE_DID_NUMBER')<
    number | undefined
>();
export const setNotInUseDidNumbers = createAction('SET_NOT_IN_USE_DID_NUMBER')<
    number | undefined
>();

export const setAllDevices = createAction('SET_ALL_DEVICES')<
    number | undefined
>();
export const setInUseDevices = createAction('SET_IN_USE_DEVICES')<
    number | undefined
>();
export const setNotInUseDevices = createAction('SET_NOT_IN_USE_DEVICES')<
    number | undefined
>();

export const setMainProducts = createAction('SET_MAIN_PRODUCTS')<
    CustomerAgreementCondition[] | undefined
>();
export const setAddonProducts = createAction('SET_ADDON_PRODUCTS')<
    CustomerAgreementCondition[] | undefined
>();


export const setBundles = createAction('SET_BUNDLES')<CounterInterface[]>()