export interface CustomerAgreementCondition {
    i_product: number;
    i_product_group?: number;
    is_addon: number;
    max_offered_quantity: number;
    product_name: string;
    used_quantity: number;
}


export enum ThresholdType {
    Time = 'Charged Time',
    Amount = 'Charged Amount'
}



export interface CounterInterface {
    full_discount_info: {
        i_vd_type: number
        peak_level: number
    }
    used: string
    threshold: string
    usage_period: number
    unit: string
    service_ratio: number
    service_name: string
    i_service: number
    dg_name: string
    period_from: string
    period_to: string
    vdp_name: string
    i_vd_dg: number
    threshold_type: ThresholdType
    discount_flag: string
    remaining: string
    peak_level: string
    i_vd_type: number

}

export interface ConflictingAddon {
    i_product: number;
    is_sf_enabled: number;
    product_name: string;
}

export interface AddonsValidationResults {
    disallowed_products: { i_product: number }[];
    service_features_conflicts: {
        sf_name: string;
        addon_precedence?: string;
        conflicting_addons: ConflictingAddon[];
    }[];
    subscription_conflicts: {
        name: string;
        i_subscription: number;
        addons: ConflictingAddon[];
    }[];
    vdp_conflicts: {
        service_name: string;
        addon_precedence?: string;
        conflicting_vdps: { i_product: number; product_name: string }[];
    }[];
}
