import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";

export const useStyle = makeStyles(() => ({

    loading: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        fontStyle: 'italic',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 400,
        color: Colors.Text3,
        gap: 14
    },


    transcriptionContainer: {
        borderTop: `1px solid ${Colors.Border}`,
        width: '100%',
        flex: 1,
        display: "flex",
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        paddingTop: 4
    },

    actionContainer: {
        display: "flex",
        flexDirection: "row",
        zIndex: 9998,
        alignItems: 'center',
        width: '100%',
        marginBottom:8,

        justifyContent: 'space-between',

        '&  > div': {
            display: 'flex'
        },

        '&  > div + div': {
            display: 'flex',
            alignItems: 'center'
        },

        '& > svg': {
            paddingLeft: '8px'
        }
    },

    greyIcon: {
        '& path': {
            fill: Colors.PlaceHolder
        },
        marginRight:20
    },

    transcriptionContainerDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'auto',
        height:380,
        minHeight:380,
        maxHeight:380,

        '& > div':{
            width:'98%'
        }
    },

    expandContainer: {
        marginTop: '20px',
        paddingTop: '14px',
        paddingBottom: '16px',
        borderTop: `1px solid ${Colors.LightGraySecondary6}`,
        display: 'flex',
        width: '100%',
        height: 0,
        flexDirection: 'column',
        opacity: 0,
    },

    expandContainerShow: {
        opacity: 1,
        height: 'auto',
        transition: 'all .5s ease-out'
    },


    expandableMembers: {
        width: '40%',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'flex-start',
        flexDirection: 'row',
        marginLeft: 14
    },

    unsetFlex: {
        flex: 'unset'
    },

    extraMargin: {
        margin: 'auto 24px'
    },
    expandIcon: {
        position: "absolute",
        right: '10px',
        bottom: '24px'
    },
}))


export interface ExtendTextTranscriptionInterface {
    detailsVisible: boolean,
    callRecordingId?: string,
    formatedPeriod: string,
    iXdr?: number,
    downloadTranscriptionPermission?: string[];
}

