import { useMemo } from 'react';

import {
    EditFaxMailboxForm,
    extensionFormDefaultValues,
} from '../components/Forms/FaxMailboxes/DetailsForm/utils';
import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { AutoAttendantListItem } from '../store/types/AutoAttendant';
import { EmailOption } from '../store/types/Voicemail';
import { FaxFormat } from '../components/Forms/AutoAttendants/Incoming/utils';
const defaultValues = {
    ...extensionFormDefaultValues,
};

export const useEditFaxMailboxesFormData = (id: string) => {
    const faxMailboxesList = useSelector(
        (state: ReduxState) => state.faxMailboxes.faxMailboxesList,
    );

    const products = useSelector(
        (state: ReduxState) => state.extensions.products,
    );

    const { voicemailSettings } = useSelector(
        (state: ReduxState) => state.extensions,
    );

    let item: AutoAttendantListItem | undefined;
    if (faxMailboxesList?.items) {
        item = fetchItem(id, faxMailboxesList.items);
    }

    const initialValues = useMemo((): EditFaxMailboxForm => {
        if (!item) {
            return defaultValues;
        }

        return {
            extensionNumber: item.extension_id || '',
            extensionName: item.name,
            extensionEmail: voicemailSettings?.ext_email || '',
            didNumber: item.numbers || [],
            action: voicemailSettings?.ext_email_action || EmailOption.None,
            format: voicemailSettings?.fax_file || FaxFormat.MultipagePDFFile,
            // Plan tab
            selectedMainProduct:
                products?.mainProducts?.find(
                    (v) => v.product_name === item?.account_info?.product_name,
                )?.i_product || -1,
            selectedAddonsProducts:
                item.account_info?.assigned_addons?.map((v) => v.i_product) ||
                [],
            portalLogin: item.account_info?.login ?? '',
            portalPassword: item.account_info?.password ?? ''
        };
    }, [faxMailboxesList, products]);

    return {
        initialValues,
        data: {
            extensionInfo: item,
        },
    };
};

const fetchItem = (
    id: number | string,
    items: AutoAttendantListItem[],
): AutoAttendantListItem | undefined => {
    const byId = items.find((o) => o.extension_id == id);

    if (!byId) {
        return items.find((o) => o.id == id);
    }

    return byId;
};
