import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as ArrowDown } from '../../assets/chevron up.svg';
import { ReactComponent as ArrowUp } from '../../assets/chevron down.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import classNames from 'classnames';
import { SearchNavigatorInterface, useStyle } from './SearchNavigator.utils';
import {useTranslation} from "react-i18next";

const SearchNavigator: React.VFC<SearchNavigatorInterface> = ({
    clickOnDown,
    clickOnUp,
    customStyle,
    onChangeValue,
    total = 0,
    matchIndex = 0,
    forceShow
}) => {
    const classes = useStyle();

    const [isActive, setIsActive] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const {t} = useTranslation()

    const resetState = () => {
        setIsActive(false);
        setValue('');
    };

    useEffect(() => {
        onChangeValue(value);
    }, [value]);

    return (
        <div
            className={classNames(
                customStyle ?? '',
                classes.searchNavigatorContainer,
            )}
            onClick={() => (!isActive ? setIsActive((prev) => !prev) : null)}
        >

            {!isActive && (
                <div
                    id={'search-text-container'}
                    className={classes.placeholderContainer}
                >
                    <SearchIcon
                        onClick={() =>
                            isActive ? setIsActive((prev) => !prev) : null
                        }
                    />
                    <div id={'search-text-placeholder'}>
                       <span> {t('common:search')}...</span>
                    </div>
                </div>
            )}

            {isActive && (
                <div className={classes.searchContainer}>
                    <SearchIcon className={classes.blue} />

                    <input
                        type={'text'}
                        value={value}
                        onChange={(event) =>
                            setValue(event.currentTarget.value)
                        }
                        onInput={(event) => setValue(event.currentTarget.value)}
                        autoFocus={true}
                    />

                    {(!total && forceShow) && (
                        <div className={classes.searchResultContainer}>
                            0/0
                        </div>
                    )}

                    {!!total && (
                        <div className={classes.searchResultContainer}>
                            {matchIndex} / {total}
                        </div>
                    )}

                    <div className={classes.actionContainer}>
                        <ArrowDown
                            onClick={ clickOnDown}
                            className={classes.active}
                        />
                        <ArrowUp
                            onClick={clickOnUp}
                            className={classes.active}
                        />

                        <Close className={classes.black} onClick={resetState} />
                    </div>
                </div>
            )}
        </div>
    );
};
export default SearchNavigator