import React, {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Box, Grid} from '@material-ui/core';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import classNames from 'classnames';
import {SipContactFormType, SipContactFormTypeProps, useStyles} from "./SipContactForm.utils";
import CustomizedRadio from "../../../Radio/Radio";
import CustomizedTextField from "../../../TextField/TextField";
import SelectField from "../../../SelectField/SelectField";
import {TransportProtocol} from "../../../../store/types/AccountFollowMeSettings";
import IconWithTooltip from "../../../Tooltip/IconWithTooltip";
import AsyncSelectField from "../../../AsyncSelectField/AsyncSelectField";
import {AccountListRequest, AccountListResponse} from "../../../../store/types/Account";
import JSONFormData from "../../../../utils/JSONFormData";
import {AxiosResponse} from "axios";
import {api} from "../../../../store/services/axios";
import {Account} from "../../../../services/endpoints";
import {useSelector} from "react-redux";
import {ReduxState} from "../../../../store/types";
import i18n from "../../../../services/i18n";

const SipContactForm: React.FC<SipContactFormTypeProps> = (
    {
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        customClass

    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {session_id, csrf_token} = useSelector((state: ReduxState) => state.auth);


    const {
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        dirty,
        errors,
        setFieldError
    } = useFormikContext<SipContactFormType>();

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    useEffect(() => {
        handleDirtyChange?.('callBarring', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callBarring', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callBarring', () => isValid);
    }, [isValid]);

    useEffect(() => {
        if (values.sipContactStatus && !values.staticAddress && !values.dynamicAddress) {
            setFieldValue('staticAddress', true);
            setFieldValue('transportProtocol', TransportProtocol.UDP);
        }

        if (!values.sipContactStatus) {
            setFieldValue('staticAddress', false);
            setFieldValue('dynamicAddress', false);
            setFieldValue('transportProtocol', '');
        }

    }, [values.sipContactStatus])


    const loadOptions = async (search: string, prevOptions: unknown[]) => {
        const limit = 30;

        const params: Partial<AccountListRequest> = {
            id: search.length > 0 ? '%' + search + '%' : undefined,
            get_not_closed_accounts: 1,
            get_statuses: 1,
            get_total: 1,
            limit: 30,
            offset: prevOptions.length,
            get_only_real_accounts: 1
        };

        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<AccountListResponse> = await api.post(
            Account.GetAccountList,
            body,
        );

        const list = res.data.account_list.map(item => ({
            iAccount: item.i_account,
            label: item.id,
            value: item.id,
            name: item.id,
            status: item.sip_status == 1 ? i18n.t<string>('screens:dashboard.registered') : i18n.t<string>('screens:dashboard.unregistered')
        }));

        return {
            options: list,
            hasMore: res.data.total > prevOptions.length + limit,
        };
    };


    return (
        <div className={classNames(classes.inputs, customClass?.container)}>
            <Grid item>
                <Box className={classes.rowBox}>
                    <SwitchWithLabel
                        className={classes.switchContainer}
                        label={t('screens:sipTrunks.sipContact')}
                        setValue={setValue}
                        value={values.sipContactStatus}
                        field={'sipContactStatus'}
                        id={'sip-contact-status-switch'}
                        disabled={values.sipContactLocked}
                    />
                    <IconWithTooltip
                        dataQa="sip-contact-tooltip"
                        tooltipText={t(
                            'tooltips:sipTrunks.sipContact',
                        )}
                        className={classes.switchContainer}
                    />
                </Box>
            </Grid>
            {values.sipContactStatus && (
                <Grid item className={classes.itemsContainer}>
                    <Box className={classes.rowBox}>
                        <div className={classes.row}>
                            <CustomizedRadio
                                value={values.staticAddress}
                                className={classes.radioContainer}
                                checked={values.staticAddress == true}
                                label={t('screens:sipTrunks.staticAddress')}
                                dataQa={`sip-contact-static-address`}
                                onChange={() => {
                                    setFieldValue('staticAddress', true);
                                    setFieldValue('dynamicAddress', false);
                                    if (!values.transportProtocol) {
                                        setFieldValue('transportProtocol', TransportProtocol.UDP);
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.row}>
                            <CustomizedRadio
                                value={values.dynamicAddress}
                                className={classes.radioContainer}
                                checked={values.dynamicAddress == true}
                                label={t('screens:sipTrunks.dynamicAddress')}
                                dataQa={`sip-contact-dynamic-address`}
                                onChange={() => {
                                    setFieldValue('dynamicAddress', true);
                                    setFieldValue('staticAddress', false);
                                }}
                            />
                        </div>
                    </Box>
                    {values.staticAddress && (
                        <div className={classes.row}>
                            <CustomizedTextField
                                className={classNames(
                                    classes.phoneNumberInput,
                                    classes.controlMargin,
                                )}
                                id="sipCldNumber"
                                label={t('screens:sipTrunks.cldNumberLabel')}
                                onChange={(e) => setFieldValue('sipCldNumber', e.target.value)}
                                value={values?.sipCldNumber}
                                dataQa="cld-number-input"
                                helperText={errors?.sipCldNumber}
                                setFieldError={setFieldError}
                                maxLength={64}
                                fixedLengthCounter
                            />
                            <CustomizedTextField
                                className={classNames(
                                    classes.controlMargin,
                                    classes.hostInput
                                )}
                                id="host"
                                label={t('screens:sipTrunks.host')}
                                onChange={(e) => setFieldValue('host', e.target.value)}
                                value={values?.host}
                                dataQa="host-input"
                                helperText={errors?.host}
                                setFieldError={setFieldError}
                                maxLength={255}
                                required
                                fixedLengthCounter
                            />
                            <CustomizedTextField
                                className={classNames(
                                    classes.controlMargin,
                                    classes.portInput,
                                )}
                                id="port"
                                label={t('screens:sipTrunks.port')}
                                onChange={(e) => setFieldValue('port', e.target.value.replace(/\D/g, ''))}
                                value={values?.port}
                                dataQa="port-input"
                                helperText={errors?.port}
                                setFieldError={setFieldError}
                                maxLength={15}
                                fixedLengthCounter
                            />
                            <SelectField
                                dataQa="transport-protocol-select"
                                label={t(
                                    'screens:advancedSettings.transportProtocol',
                                )}
                                items={Object.values(TransportProtocol)}
                                value={values.transportProtocol}
                                onChange={(_, value) =>
                                    setFieldValue('transportProtocol', value)
                                }
                                className={classes.transportProtocolSelect}
                                classes={{
                                    container: classNames(
                                        classes.transportProtocolSelect,
                                        classes.controlMargin,
                                    ),
                                }}
                                disableClearable
                            />
                        </div>

                    )}
                    {values.dynamicAddress && (
                        <div className={classes.row}>
                            <AsyncSelectField
                                id={'dynamicAddressEntity'}
                                title={t('screens:sipTrunks.dynamicListLabel')}
                                loadOptions={loadOptions}
                                value={values.dynamicAddressEntity}
                                onChange={(value) => {
                                    if(value?.iAccount) {
                                        setFieldError('dynamicAddressEntity', undefined);
                                    }
                                    setFieldValue('dynamicAddressEntity', value);
                                }}
                                isOptionSelected={(option) => {
                                    return option.value == values?.dynamicAddressEntity?.name;
                                }}
                                cacheUniqs={[values.dynamicAddressEntity]}
                                required
                                className={classes.selectFiled}
                                helperText={errors.dynamicAddressEntity || ''}
                                formatOptionLabel={(option, labelMeta) => {
                                    const textColorStyle = option.status == t('screens:dashboard.registered') ? classes.register : classes.unregister;
                                    return (<div className={classes.optionContainer}>
                                        <span className={classes.optionText}>{option.label}</span>
                                        {labelMeta.context == 'menu' && (
                                            option.status && <span className={textColorStyle}>{option.status}</span>)
                                        }
                                    </div>)
                                }}
                                disableClearable
                            />
                        </div>
                    )}
                </Grid>
            )}
        </div>
    );
};

export default SipContactForm;
