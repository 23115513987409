import {IconButton, makeStyles} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import {Colors} from "../../styles/Colors";
import {portalUserStatusBasedOnDates} from "./utils";
import React from "react";
import {useTranslation} from "react-i18next";
import { useIndividualTypeHook } from "../../hooks/agents/useIndividualTypeHook";
import { useEditMyProfileFormData } from "../../hooks/useEditMyProfileFormData";
import classNames from 'classnames';

export const useStyles = makeStyles(() => ({
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: Colors.White,
        alignItems: 'center',
        '& h3': {
            fontSize: 22,
            margin: 0,
        },
        '& p': {
            fontSize: 16,
            color: Colors.Gray5,
            margin: 0,
        },
    },

    extensionHeader: {
        display: 'flex',
        alignItems: 'center',
        '& label': {
            marginRight: 18,
            fontWeight: 300,
            fontSize: 32,
        },
        '& p': {
            fontWeight: 500,
            fontSize: 32,
            color: Colors.Text,
        },
    },

    subTitle: {
        color: Colors.Gray5,
        fontSize: 22,
    },

    statusWrapper: {
        display: 'flex',
        flexDirection: 'column',
        '& > :last-child': {
            color: Colors.Text,
        },
    },
    status: {
        fontWeight: 500,
    },

    statusesContainer: {
        width: 'fit-content',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },

    secondStausWrapper: {
        marginLeft: 64,
    }
}));

interface HeaderProps {
    backToList: () => void,
    initialValues: { activationDate: string, expirationDate: string, timezone: string, login: string },
    currentUserTimeZoneName: string
}

const Header: React.VFC<HeaderProps> = (
    {backToList, initialValues, currentUserTimeZoneName}
) => {
    const {t} = useTranslation()
    const classes = useStyles();
    const {isSupervisor} = useIndividualTypeHook();
    const {data} = useEditMyProfileFormData();

    return (

        <div className={classes.headerWrapper}>
            <div className={classes.extensionHeader}>
                <IconButton style={{marginRight: 10}} onClick={backToList}>
                    <ChevronLeft htmlColor={Colors.Text}/>
                </IconButton>
                <label>{t('screens:portalUsers.portalUser')}</label>
                <p className={classes.subTitle}>{initialValues.login}</p>
            </div>
            <div className={classes.statusesContainer}>
                <div className={classes.statusWrapper}>
                    <p>{t('common:status')}</p>
                    <p className={classes.status}>
                        {
                            portalUserStatusBasedOnDates(
                                initialValues.activationDate ? initialValues.activationDate + '' : null,
                                initialValues.expirationDate ? initialValues.expirationDate + '' : null,
                                initialValues.timezone, currentUserTimeZoneName)
                        }
                    </p>
                </div>
                {isSupervisor && data.assigned_extension_id
                    && (
                        <div className={classNames(classes.statusWrapper, classes.secondStausWrapper)}>
                            <p>{t('screens:dashboard.extension')}</p>
                            <p className={classes.status}>
                                {data.assigned_extension_id}
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Header