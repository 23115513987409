import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";

export const useStyle = makeStyles(() => ({
    loading:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        alignSelf:'flex-start',
        fontStyle:'italic',
        fontSize:'16px',
        lineHeight:'22px',
        fontWeight:400,
        color: Colors.Text3,
        gap: 14
    },

    transcriptionContainer: {
        borderTop: `1px solid ${Colors.LightGraySecondary6}`,
        width: '100%',
        flex: 1,
        display: "flex",
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        paddingTop:4
    },

    actionContainer:{
        position:"absolute",
        display:"flex",
        flexDirection:"row",
        right:10,
        top:0,
        zIndex:9998,
        alignItems:'center',

        '& > svg':{
            marginLeft:'8px'
        }
    },

    greyIcon:{
        '& path':{
            fill:Colors.PlaceHolder
        }
    },

    transcriptionContainerDetails: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'auto',
        paddingLeft: 15
    },

    expandContainer: {
        marginTop: '20px',
        paddingTop: '14px',
        paddingBottom: '16px',
        borderTop: `1px solid ${Colors.LightGraySecondary6}`,
        display: 'flex',
        width: '100%',
        height: 0,
        flexDirection: 'column',
        opacity: 0,
    },

    expandContainerShow: {
        opacity: 1,
        height: 'auto',
        transition: 'all .5s ease-out'
    },


    expandableMembers: {
        width: '40%',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'flex-start',
        flexDirection: 'row',
        marginLeft: 14
    },

    unsetFlex: {
        flex: 'unset'
    },

    extraMargin: {
        margin: 'auto 24px'
    },
    expandIcon: {
        position: "absolute",
        right: '10px',
        bottom: '24px'
    },
}))


export interface TextTranscriptionInterface {
    detailsVisible: boolean,
    open: boolean,
    onClickExpand: () => void,
    callRecordingId?: string,
    divMaxHeight?:number,
    formatedPeriod:string,
    iXdr?:number
}

