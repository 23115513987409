import { AxiosResponse } from 'axios';
import { select, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { Account } from '../../../../services/endpoints';
import { prepareCallForwardingRule } from '../../../../utils/extensions/prepareCallForwardingRule';
import JSONFormData from '../../../../utils/JSONFormData';
import { EditExtensionForm } from '../../../../views/Extensions/ExtensionDetails/utils';
import { api } from '../../../services/axios';
import {
    AccountFollowMeSettings,
    FollowMeSequence,
} from '../../../types/AccountFollowMeSettings';
import {
    ForwardModeFlag,
    ServiceFeatureName,
} from '../../../types/ServiceFeature';
import { editServiceFeatures } from '../serviceFeatures/saga';
import { CallForwardingRule } from '../../../../components/Forms/Extensions/CallForwarding/CallForwardingForm.utils';

export function* getExtensionCallForwardingSettings(
    action: ActionType<typeof actions.getExtensionFollowMeSettings.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    try {
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            i_account: action.payload.accountId,
            detailed_info: '1',
            with_period_definition: 1,
        });

        const res: AxiosResponse<AccountFollowMeSettings> = yield api.post(
            Account.GetAccountFollowMe,
            body,
        );

        yield put(actions.getExtensionFollowMeSettings.success(res.data));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getExtensionFollowMeSettings.failure(error));
    }
}

export function* editExtensionCallForwardingSettings(
    data: Partial<EditExtensionForm>,
    action:
        | ActionType<typeof actions.editExtension.request>
        | ActionType<typeof actions.editAutoAttendant.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const accountFollowMeSettings: AccountFollowMeSettings = yield select(
        (state) => state.extensions.accountFollowMeSettings,
    );

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        i_account: action.payload.accountId,
    });

    if (data.callForwardingStatus !== undefined || data.callForwardingMethod) {
        const callStatus =
            data.callForwardingStatus ??
            action.payload.initialValues.callForwardingStatus;
        const callForwardingMethod =
            data.callForwardingMethod ||
            action.payload.initialValues.callForwardingMethod;

        yield editServiceFeatures(action.payload.accountId, [
            {
                name: ServiceFeatureName.ForwardMode,
                effective_flag_value: callStatus
                    ? callForwardingMethod
                    : ForwardModeFlag.NoForwarding,
                flag_value: callStatus
                    ? callForwardingMethod
                    : ForwardModeFlag.NoForwarding,
                attributes: [],
            },
        ]);

        if (!callStatus) {
            return;
        }
    }

    const i_followme_account =
        accountFollowMeSettings?.followme_info?.i_account;
    const simpleForwardingRules = [
        ForwardModeFlag.SimpleForwarding,
        ForwardModeFlag.ForwardToSIPUri,
    ];

    if (
        (data.callForwardingMethod &&
            simpleForwardingRules.includes(data.callForwardingMethod)) ||
        (action.payload.initialValues.callForwardingMethod ==
            action.payload.changedValues.callForwardingMethod &&
            simpleForwardingRules.includes(
                action.payload.initialValues.callForwardingMethod,
            ))
    ) {

        body.setParams({
            i_account: action.payload.accountId,
            followme_info: {
                sequence: FollowMeSequence.Order,
            },
        });

        yield api.post(Account.UpdateAccountFollowMe, body);
    } else if (
        data.callForwardingRuleOrder ||
        (!i_followme_account && data.callForwardingRules) ||
        (action.payload.initialValues.setDefaultFollowMeSequence &&
            data.callForwardingRules?.length)
    ) {
        body.setParams({
            i_account: action.payload.accountId,
            followme_info: {
                sequence:
                    data.callForwardingRuleOrder ||
                    action.payload.initialValues.callForwardingRuleOrder,
            },
        });

        yield api.post(Account.UpdateAccountFollowMe, body);
    }

    if (data.callForwardingRules) {
        const callForwardingMethod =
            data.callForwardingMethod ||
            action.payload.initialValues.callForwardingMethod;

        const multipleRulesMethods = [
            ForwardModeFlag.FollowMe,
            ForwardModeFlag.AdvancedForwarding,
        ];

        const method =
            data.callForwardingMethod ??
            action.payload.initialValues.callForwardingMethod;

        const numbers = data.callForwardingRules.map((rule) =>
            prepareCallForwardingRule(
                rule,
                callForwardingMethod,
                action.payload.accountId,
            ),
        );

        if (numbers.length) {
            const idToDelete = getDeleteFollowMeNumberId(
                action.payload.initialValues.callForwardingRules,
                data.callForwardingRules,
            );
            const initMethod =
                action.payload.initialValues.callForwardingMethod;

            if (idToDelete) {
                if (
                    method != initMethod &&
                    !multipleRulesMethods.includes(method) &&
                    !multipleRulesMethods.includes(initMethod)
                ) {
                    yield callDeleteFallowMeNumber(body, idToDelete);
                }
            }

            body.setParams({
                followme_numbers: numbers,
            });

            yield api.post(Account.UpdateFollowMeNumberList, body);
        } else if (data.callForwardingDeletedRules?.length) {
            const idsToDelete = data.callForwardingDeletedRules.filter(
                (item) => !item.id.toString().includes('created'),
            );
            if (idsToDelete.length) {
                for (let i = 0; i < idsToDelete.length; i++) {
                    yield callDeleteFallowMeNumber(body, idsToDelete[i].id);
                }
            }
        }
    }
}

function* callDeleteFallowMeNumber(body: JSONFormData, id: string) {
    body.setParams({
        i_follow_me_number: id,
    });

    yield api.post(Account.DeleteFollowMeNumber, body);
}

function getDeleteFollowMeNumberId(
    initRules: CallForwardingRule[],
    newRules: CallForwardingRule[],
): string | null {
    let id = null;
    if (
        initRules.length &&
        initRules[0].id &&
        newRules.length &&
        initRules[0].id != newRules[0].id
    ) {
        id = initRules[0].id;
    }

    return id;
}
