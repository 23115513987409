import React, { useMemo } from "react";
import BarLoader from "./BarLoader";
import Config from '../../config.json';
import AnimatedNumberText from "../AnimatedNumber/AnimatedNumber";

interface BarLoaderWrapperProps {
    isLoading: boolean,
    value?: number,
    className?: string,
    dataTestId?: string,
}

const BarLoaderWrapper: React.FC<BarLoaderWrapperProps> = ({
    isLoading, 
    value,
    className,
    children,
    dataTestId
}
) => {

    const hasWebSocket = useMemo(() => {
        if(!Config.WEBSOCKET_SERVER_URL?.length) {
            return false;
        }
        return true;
    }, [Config]);

    return isLoading ? 
            <BarLoader dataTestId={dataTestId} /> : 
        children ? <>{children}</> :
        hasWebSocket ? 
            <div className={className} data-testid={dataTestId}>{value}</div>
            : <AnimatedNumberText
                value={value??0}
                className={className}
                dataTestId={dataTestId}
            />;
}

export default BarLoaderWrapper