import React, {useEffect, useRef, useState} from "react";
import TimeSlot from "./TimeSlot";
import SearchableText from "./SearchableText";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import {Colors} from "../../styles/Colors";
import {ReactComponent as Edit} from '../../assets/edit.svg';
import {useDispatch} from "react-redux";
import {actions} from "../../store";
import {CallSegment} from "../../store/reducers/calls/reducer";


const useStyle = makeStyles(() => ({
    container: {
        display: "flex",
        width: '90%',
        justifyItems: 'flex-start',
        columnGap: 37,
        padding: '2px 4px',
        borderWidth: 1,
        borderColor: 'transparent',
        borderStyle: "solid",
        borderRadius: '4px',
        transition: 'all 0.2s ease-in-out',
        alignItems: 'center',

        '& svg:last-of-type ': {
            visibility: 'hidden',
            opacity: 0,
            maxWidth: 16,
            maxHeight: 16,
            cursor: 'pointer',
            marginTop: 5
        },

        '&:hover svg:last-of-type': {
            visibility: 'visible',
            opacity: 1
        },

        '&:hover': {
            backgroundColor: Colors.LightGraySecondary7
        },

        '& > div:first-child': {
            borderBottomStyle: 'dashed',
            borderBottomWidth: 1,
            borderBottomColor: 'transparent',
        },

        '&:hover > div:first-child': {
            borderBottomStyle: 'dashed',
            borderBottomWidth: 1,
            borderBottomColor: Colors.Secondary2,
            color: Colors.Secondary2
        }

    },

    full: {
        width: 'unset',
        gap: 8,
        display: 'flex',
        padding: '5px 8px',
        alignItems: 'center',
        alignSelf: 'stretch'
    },

    actionContainer: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        padding: '0 8px',
        minWidth:'40px',
        justifyItems:'start'
    },

    textArea: {
        display: 'flex',
        width: '85%',
        justifyItems: 'flex-start',
        columnGap: '37px',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderRadius: '4px',
        transition: 'all 0.2s ease-in-out',
        fontFamily: 'inherit',
        resize: 'none',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: 'inherit',
        overflow: 'hidden',
        lineHeight: '19px',
    },

    save: {
        color: Colors.Secondary2,
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        cursor: 'pointer',
        alignSelf: 'center',
        padding: '0 8px'
    },

    saveDisabled: {
        color: Colors.Secondary2,
        opacity: '.5',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        cursor: 'not-allowed',
        alignSelf: 'center',
        padding: '0 8px'
    },

    edited: {
        color: Colors.Secondary2,
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        alignSelf: 'center',
        padding: '0',
        fontStyle: 'italic'
    },

    edit: {
        borderRadius: '4px 4px 0px 0px',
        borderBottom: '2px solid' + Colors.Secondary2,
        background: Colors.White,
        boxShadow: '-1px 0px 0px 0px rgba(0, 0, 0, 0.15) inset, 1px 0px 0px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.15) inset',
        '&:hover': {
            backgroundColor: Colors.White
        },

        '&:hover > div:first-child': {
            borderBottomStyle: 'dashed',
            borderBottomWidth: 1,
            borderBottomColor: Colors.Secondary2,
            color: Colors.Secondary2
        },

        '& > div:first-child': {
            borderBottomStyle: 'dashed',
            borderBottomWidth: 1,
            borderBottomColor: Colors.Secondary2,
            color: Colors.Secondary2
        }


    },

    active: {
        borderColor: Colors.Primary,
        backgroundColor: Colors.White,
        transition: 'all 0.2s ease-in-out',


        '& div:first-child  svg': {
            visibility: 'visible',
            opacity: 1
        },

        '&:hover': {
            backgroundColor: Colors.White
        },

        '& > div:first-child': {
            borderBottomStyle: 'dashed',
            borderBottomWidth: 1,
            borderBottomColor: 'transparent'
        },

        '&:hover > div:first-child': {
            borderBottomStyle: 'dashed',
            borderBottomWidth: 1,
            borderBottomColor: Colors.Primary,
            color: Colors.Primary
        }
    }
}))


interface TranscriptionRowInterface {
    start: string
    end: string
    duration?: string
    search?: string,
    text: string,
    active: boolean,
    id: string
    matchIndex?: number
    rowNumber?: number
    searchMatrix?: number[][],
    callSegment?: CallSegment,
    full?: boolean
}

const TranscriptionRow: React.VFC<TranscriptionRowInterface> = (
    {
        id,
        search,
        start,
        end,
        text,
        active,
        matchIndex,
        rowNumber,
        searchMatrix,
        callSegment,
        full,
        duration
    }) => {

    const classes = useStyle();

    const dispatch = useDispatch();

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const mainContainerRef = useRef<HTMLDivElement | null>(null);

    const [isInEditMode, setIsInEditMode] = useState<boolean>(false)
    const [isSaved, setIsSaved] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [displayText, setDisplayText] = useState<string>(text)

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
    };
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
    };

    const toggleEditMode = () => {
        setValue(!isInEditMode ? displayText : '')
        setIsInEditMode(prev => !prev);
    }
    const handleSave = () => {
        setIsSaving(true)

        const callback = (success: boolean) => {
            if (success) {
                setDisplayText(value)
                toggleEditMode();

            }
            setIsSaved(true)
            setIsSaving(false)
        }

        callSegment && dispatch(actions.updateCallRecordingSegment.request({...callSegment, text: value, callback}))
    }

    const searchActive = rowNumber !== undefined && matchIndex !== undefined && !!id
    const notNullMatrix = searchActive && searchMatrix && searchMatrix[rowNumber]?.length
    let index: number | undefined

    const element = document.getElementById('highlighted-part');

    if (searchActive) {
        // @ts-ignore
        index = notNullMatrix && searchMatrix[rowNumber].findIndex(el => el === matchIndex)

        if (index !== -1 && index !== undefined && element) {
            element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
        }
    } else if (active && element) {
        element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
    }

    const autoResize = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        const element = mainContainerRef.current;
        if (element && !element.contains(event.target as Node)) {
            setIsInEditMode(false);
            setValue('')
        }
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        document.addEventListener('mousedown', handleClickOutside);

        if (textarea) {
            autoResize();
            textarea.addEventListener('input', autoResize);
        }

        return () => {
            textarea && textarea.removeEventListener('input', autoResize);
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [value]);


    return (
        <div
            ref={mainContainerRef}
            id={id} className={classNames(
            classes.container,
            (active && !isInEditMode) && classes.active,
            isInEditMode && classes.edit,
            full && classes.full
        )}>
            <TimeSlot
                time1={start}
                time2={end}
                active={active && !isInEditMode}
                duration={duration}
            />

            {!isInEditMode && (
                <>
                    <SearchableText
                        text={displayText}
                        search={search}
                        bold={active}
                        activeFoundIndex={(searchActive && index !== undefined) ? index : undefined}
                        markedId={'highlighted-part'}
                    />
                    <div className={classes.actionContainer}>
                        <Edit onClick={toggleEditMode}/>
                        {isSaved && <span className={classes.edited}>Edited</span>}
                    </div>
                </>
            )}

            {isInEditMode && (
                <>
                <textarea
                    ref={textareaRef}
                    value={value}
                    onChange={handleChange}
                    onInput={handleInput}
                    className={classes.textArea}
                    autoFocus={true}
                    disabled={isSaving}
                />
                    <span className={!isSaving ? classes.save : classes.saveDisabled} onClick={handleSave}>Save</span>
                </>
            )}

        </div>
    )
}

export default TranscriptionRow