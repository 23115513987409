import React from 'react';
import {CounterInterface, ThresholdType} from '../../store/types/CustomerAgreementCondition';
import {useTranslation} from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
import {useStyles} from './productRowUtils';
import TwoLineCell from "../Calls/TwoLineCell";
import {roundUp} from "../../utils/roundUp";
import CustomizedTooltip from "../Tooltip/Tooltip";
import BundleRowTooltip from "./BundleRowTooltip";
import {BundleRowProps, convertToMinutesAndSeconds, getLabel} from "./BundleRow.utils";


export const testId = 'bundle-row-data-wrapper'

const BundleRow: React.VFC<BundleRowProps> = ({product, lastRow}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const tempUsed = Number(product.used)
    const tempThreshold = Number(product.threshold)

    const percentValue = tempUsed === tempThreshold ? 100 : (100 * tempUsed) / tempThreshold

    const title = t('enums:standardServiceName.' + product.i_service, product.service_name)

    const isNotActive = (product: CounterInterface): boolean => {
        return product.discount_flag === 'U' && !Number(product.threshold) && !Number(product.remaining)
    }

    const unitsShort = t(`units:short.${product.unit}`, product.unit, title)

    let barColorStyle: string
    if (product.threshold === 'N/A') {
        barColorStyle = classes.unlimitedProgressBar
    } else if (Number(product.used) < Number(product.threshold)
    ) {
        barColorStyle = classes.bundleProgressBar
    } else if (Number(product.used) === 0 || isNotActive(product)) {
        barColorStyle = classes.unlimitedProgressBar

    } else if (Number(product.used) == Number(product.threshold)) {
        barColorStyle = classes.fullProgressBar
    } else {
        barColorStyle = classes.fullProgressBar
    }

    return (
        <CustomizedTooltip
            title={<BundleRowTooltip product={product}/>}
            copy={false}
            classes={{tooltipContainer: classes.tooltipContainer}}
            disableHoverListener={isNotActive(product)}
            above={true}
            dataQa={'bundle-row-tooltip-wrapper'}
        >
            <div className={lastRow ? classes.mainContainerLast : classes.mainContainer}
                 data-qa={testId}
                 data-testid={testId}
            >
                <div className={classes.firstColumn}>

                    <TwoLineCell
                        headerText={title}
                        descriptionText={product.dg_name}
                        headerClassName={classes.smallMargin}
                    />
                </div>

                <div className={classes.firstColumn}>

                    {isNotActive(product) ? (
                        <LinearProgress
                            className={classNames(
                                classes.progressBar,
                                barColorStyle
                            )}
                            variant="determinate"
                            value={0}
                        />
                    ) : (
                        <LinearProgress
                            className={classNames(
                                classes.progressBar,
                                barColorStyle
                            )}
                            variant="determinate"
                            value={percentValue > 100 ? 100 : percentValue}
                        />

                    )}

                </div>

                <div className={classes.secondColumn}>

                    {isNotActive(product) ?
                        (<span>{t('screens:dashboard.notActiveYet')}</span>) :
                        (
                            <>
                        <span
                            className={classes.usedQuantity}
                            data-testid="product-row-used-quantity"
                            data-qa="product-row-used-quantity"
                        >

                            {
                                product.threshold_type === ThresholdType.Time ?
                                    convertToMinutesAndSeconds(Number(product.used), product.unit, product.service_ratio, unitsShort)
                                    : product.used + ' ' + unitsShort
                            }
                        </span>
                                {' '}
                                <span
                                    className={classes.maxQuantity}
                                    data-testid="product-row-max-quantity"
                                    data-qa="product-row-max-quantity"
                                >
                       /{' '}
                                    {product.threshold === 'N/A'
                                        ? t('screens:dashboard.unlimited')
                                        : roundUp(Number(product.threshold), 2)}
                         </span>
                            </>

                        )}

                </div>
                <div className={classes.thirdColumn}>
                    <div className={classes.mainProductLabel}>
                        <span>{getLabel(product)}</span>
                    </div>
                </div>
            </div>

        </CustomizedTooltip>

    );
};

export default BundleRow;
