import classNames from 'classnames';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import AnimatedNumberText from '../../../components/AnimatedNumber/AnimatedNumber';
import {
    CallVolumesWidgetData,
    WidgetCallVolumesData,
    WidgetCallVolumesTimeRange,
    WidgetPresantationType
} from "../../../store/types/Wallboard";
import {useStyles} from "./TransactionsList.utils";
import {MenuItem, Select} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Colors} from '../../../styles/Colors';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../store';
import {ReduxState} from '../../../store/types';
import {DropDownDictionaryItem} from '../../CallHistory/ActiveCalls/ActiveCalls.utils';
import {getDurationFromSec} from '../../../utils/transformers';
import WidgetLegendItem, {ChartLegendItem} from './WidgetLegendItem';
import WidgetLoadingMask from '../WidgetLoadingMask';
import {ServiceInfo} from "../../../store/types/Billing";
import AccessDeniedForWidget from "../../../components/PermissionProvider/AccessDeniedForWidget";

export type CallVolumesWidgetProps = {
    data: WidgetCallVolumesData,
    id: string,
    presentation: WidgetPresantationType,
    disableCustomRange: boolean,
    darkMode: boolean
};

const TransactionsWidget: React.VFC<CallVolumesWidgetProps> = (
    {
        data,
        id,
        presentation,
        disableCustomRange,
        darkMode
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [customRange, setCustomRange] = useState<WidgetCallVolumesTimeRange>(data.timeRange);

    const [transactionList, setTransactionList] = useState<ServiceInfo[]>([]);
    const [errorsExist, setErrorExist] = useState<boolean>(false)

    function refresh(data: CallVolumesWidgetData) {
        setTransactionList(data.transactionsList.find(el => el.id === id)?.items || [])
        dispatch(actions.refreshWidgetCompleted.request(id));
    }

    const {refreshWidgetsQueue} = useSelector((state: ReduxState) => state.wallboard);
    const {widgetsErrors} = useSelector((state: ReduxState) => state.wallboard);
    const isLoadingChart = useMemo(() => !!(refreshWidgetsQueue?.find(e => e.id === id)),
        [refreshWidgetsQueue, id]);

    const resizeObserver = new ResizeObserver(entries => {
        if (entries && entries.length && entries[0].target) {
            //@ts-ignore
            const offsetTop = entries[0].target?.offsetTop || 0;
            const el = document.getElementById("customTimeRange_doughnut_" + id)?.parentNode as Element;
            if (!el) return;

            let h = 0;
            if (offsetTop <= 40) {
                h = -8;
            } else if (offsetTop <= 48) {
                h = -4;
            }

            //@ts-ignore
            el.style.top = h + 'px';
        }
    });

    useEffect(() => {

        if(widgetsErrors && widgetsErrors[id])
        {
            setErrorExist(true)
        }else{
            setErrorExist(false)
        }

    }, [widgetsErrors]);

    useEffect(() => {
        const itm = refreshWidgetsQueue?.find(e => e.id === id);
        if (itm && itm.dataHasLoaded) {
            refresh(itm.data as CallVolumesWidgetData);
        }
    }, [refreshWidgetsQueue]);

    useEffect(() => {
        const _id = 'native-select-container' + id;
        const el = document.getElementById(_id);
        const subItems = el?.getElementsByClassName('MuiSelect-root');
        const name = widgetCallVolumesTimeRangeList
            .find(e => e.value === customRange)?.name;
        if (subItems && subItems?.length > 0) {
            subItems[0].innerHTML = t('screens:wallboard.callsInLast', {
                value: name
            });
        }
    }, [id, customRange, data, presentation]);

    const widgetCallVolumesTimeRangeList = useMemo(
        () => {
            const values = [
                WidgetCallVolumesTimeRange.min60,
                WidgetCallVolumesTimeRange.min180,
                WidgetCallVolumesTimeRange.min300,
                WidgetCallVolumesTimeRange.min420,
                WidgetCallVolumesTimeRange.min1440,
                WidgetCallVolumesTimeRange.min4320,
                WidgetCallVolumesTimeRange.min7200,
                WidgetCallVolumesTimeRange.min10080
            ];
            if (!values.find(e => e === data.timeRange)) {
                values.push(data.timeRange);
                values.sort((a, b) => (a as number) - (b as number));
            }
            return values
                .map((v) => ({
                    name: t(`enums:widgetCallVolumesTimeRange.${v}`),
                    value: v as WidgetCallVolumesTimeRange,
                } as DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>));
        },
        [data],
    );

    const chartData = useMemo(() => {
        let retArray = transactionList.map(el => ({
            label: el.i_service === 3 ? t('enums:standardServiceName.' + el.i_service) : el.name,
            value: el.total_sessions,
            color: el?.color ?? Colors.Primary3,
            testId: 'transaction-label-i-service-' + el.i_service,
            durationInSec: el.charged_quantity,
            durationInSecString: ''
        } as ChartLegendItem))


        if (!retArray) {
            retArray = []
        }

        for (const itm of retArray) {
            itm.durationInSecString = getDurationFromSec(itm.durationInSec).string;
        }

        if (retArray.every(el => el.value === 0)) {
            retArray.push({
                label: '',
                value: 1,
                color: darkMode ? '#494A55' : '#D9D9D9',
                testId: 'empty-chart-item',
                durationInSec: 0,
                durationInSecString: '-'
            });
        }

        return retArray;
    }, [transactionList, data, customRange, darkMode]);

    const dataset = useMemo(() => {
        return {
            labels: chartData.map((v) => v.label),
            datasets: [
                {
                    data: chartData.map((v) => v.value),
                    backgroundColor: chartData.map((v) => v.color),
                    borderColor: chartData.map(() => darkMode ? '#201F2B' : Colors.White),
                },
            ],
        };
    }, [chartData]);

    const dataSum = useMemo(() => {
        return chartData
            .filter(e => e.durationInSecString !== '-')
            .map(e => e.value)
            .reduce((v, w) => v + w, 0);
    }, [chartData]);

    const isZeroDataSum = useMemo(() => {
        return dataSum === 0;
    }, [dataSum]);


    const isEmptyData = useMemo(() => {
        if (isLoadingChart) return false;
        if (transactionList != undefined && transactionList.length === 0)
            return true;
        const hasNoEmpty = false;

        return !hasNoEmpty;
    }, [transactionList, isLoadingChart, customRange, data]);


    return (<>
            <div id={'transactions_volumes_widget_' + id}
                 className={classNames(classes.widgetMainContainer)}>
                <div className={classes.mainContainer}>

                    {errorsExist && (
                        <AccessDeniedForWidget/>
                    )}

                    {!errorsExist && (
                        <div className={classes.nativeSelectContainer} id={'native-select-container' + id}>
                            <Select
                                id={"customTimeRange_doughnut_" + id}
                                value={customRange as number}
                                onChange={(e) => {
                                    setCustomRange(e.target.value as WidgetCallVolumesTimeRange);
                                    dispatch(actions.setCustomWidgetRange({
                                        id,
                                        // @ts-ignore
                                        periodRange: e.target.value as WidgetCallVolumesTimeRange
                                    }))
                                    dispatch(actions.refreshWallboardTrigger.request({id, refreshImmediately: true}))
                                }}
                                disabled={disableCustomRange}
                                disableUnderline
                                variant='standard'
                                className={classNames(classes.nativeSelect, darkMode && classes.nativeSelectDarkMode)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "right"
                                    },
                                    getContentAnchorEl: null
                                }}
                                data-testid={'custom-time-range'}
                            >
                                {
                                    widgetCallVolumesTimeRangeList.map((r, i) => (
                                        <MenuItem
                                            value={r.value as number}
                                            key={'option_key_' + i}
                                            className={classes.nativeSelectOption}
                                        >
                                            {r.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                    )}
                    {!errorsExist && (
                        <div className={classes.chartMainContainer}>
                            <div className={classNames(classes.chartContainer,
                                isEmptyData && classes.chartContainerEmptyData)}>
                                <div
                                    className={classNames(
                                        classes.chartPlaceholder,
                                        isLoadingChart && classes.chartLoadingBackground,
                                        isEmptyData && classes.chartEmptyBackground,
                                    )}
                                />
                                {!isLoadingChart && !errorsExist && (
                                    <Doughnut
                                        className={classes.chart}
                                        id={'donut_char_' + id}
                                        data={dataset}
                                        options={{
                                            responsive: true,
                                            //@ts-ignore
                                            cutout: '72%',
                                            //@ts-ignore
                                            borderWidth: isZeroDataSum ? 0 : 1,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                                tooltip: isZeroDataSum ? {
                                                    enabled: false
                                                } : {
                                                    enabled: true,
                                                    backgroundColor: 'rgba(51, 51, 51, 1)',
                                                    callbacks: {
                                                        label: function (tooltipItem) {
                                                            const dataObject = chartData[tooltipItem.dataIndex];
                                                            if (dataObject.durationInSecString === '-') {
                                                                return t('common:noData');
                                                            }
                                                            const labelValue = dataObject.label;
                                                            const totalDuration = dataObject.durationInSecString;
                                                            return labelValue + ': ' + totalDuration;
                                                        },
                                                    }
                                                }
                                            },
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                )}

                                {!isLoadingChart && (
                                    <div className={classes.sumValue}
                                         ref={(el) => {
                                             if (el) {
                                                 resizeObserver.observe(el);
                                             }
                                         }}>
                                        <AnimatedNumberText
                                            className={classNames(classes.sumText, darkMode && classes.sumTextDarkMode)}
                                            hideThousands
                                            value={dataSum}
                                            dataTestId="doughnut-chart-sum-value"
                                            dataQa="doughnut-chart-sum-value"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {chartData.length && (
                        <div className={classes.legendContainer}>
                            {chartData.filter(el => el.label.length)?.map((el, index) => {
                                return (
                                    <div key={index} className={classes.legendRow}>
                                        <div className={classes.legendSubRow}>
                                            <div>
                                                <WidgetLegendItem
                                                    itm={el}
                                                    isLoadingChart={isLoadingChart}
                                                    darkMode={darkMode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                </div>
            </div>
            <WidgetLoadingMask
                isLoadingChart={isLoadingChart}
                darkMode={darkMode}
                customClasses={{
                    container: classNames(presentation === WidgetPresantationType.doughnut && classes.loadingBackgroundDoughnut,
                        presentation === WidgetPresantationType.doughnut && classes.loadingBackgroundList),
                    label: classNames(presentation === WidgetPresantationType.doughnut && classes.loadingLabelDoughnut,
                        presentation === WidgetPresantationType.doughnut && classes.loadingLabelList),
                }}
            />
        </>
    );
};

export default TransactionsWidget;