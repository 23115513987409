import React from "react";
import DashboardSection from "../../layouts/DashboardSection";
import BundleRow from "./BundleRow";
import {CounterInterface} from "../../store/types/CustomerAgreementCondition";
import {useTranslation} from "react-i18next";

// import {mockDataBundles} from "./BundleRow.test";

interface BundlesSectionInterface {
    bundles?: CounterInterface[],
    mainContainerCustomClass?: string
}

export const BundlesSection: React.VFC<BundlesSectionInterface> = (
    {bundles, mainContainerCustomClass}) => {

    const {t} = useTranslation();
    const title = t('screens:dashboard.bundles')
    const noDataMsg = t('screens:dashboard.noBundlesData')

    const noData = bundles && !bundles.length || false
    const isLoading = !bundles

    const data = bundles ?? []
    // const data = mockDataBundles.counter_list ?? []

    const lastIndex = bundles?.length ? bundles?.length - 1 : 0
    // const lastIndex = mockDataBundles.counter_list.length ? mockDataBundles.counter_list.length - 1 : 0

    return (
        <DashboardSection
            title={title}
            noIsVisible={noData}
            isLoading={isLoading}
            noDataMsg={noDataMsg}
            mainContainerCustomClass={mainContainerCustomClass}
        >
            {data?.map((v, index) => (
                <BundleRow key={v.i_vd_dg} product={v} lastRow={index == lastIndex}/>
            ))}

        </DashboardSection>
    )

}