import { useSelector } from 'react-redux';
import { APIErrorInterface, ReduxState } from '../store/types';
import { useMemo } from 'react';
import { ServiceFeatureName } from '../store/types/ServiceFeature';
import {
    extensionFormDefaultValues,
    ExtensionFormType,
} from '../components/Forms/Extensions/Extension/ExtensionForm.utils';
import { Subdivision } from '../store/types/Subdivision';
import { Country } from '../store/types/Country';
import { TimeZone } from '../store/types/TimeZone';
import { ConfigData } from '../store/types/ConfigData';
import { getServiceFeatureValue } from '../utils/extensions/getServiceValue';
import { YesNo } from '../store/types/CallScreening';

export const useExtensionFormTab = () => {
    const extension = useSelector(
        (state: ReduxState) => state.extensions.extensionDetails,
    );

    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.extensions.errors,
    );

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>((state) => [
        {
            i_country_subdivision: 0,
            iso_3166_1_a2: '',
            iso_3166_2: '',
            name: '',
        },
        ...state.generic?.subdivisionsList,
    ]);

    const countriesList = useSelector<ReduxState, Country[]>(
        (state) => state.generic.countriesList,
    );

    const timeZonesList = useSelector<ReduxState, TimeZone[]>(
        (state) => state.generic.timeZonesList,
    );

    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );

    const {
        mohDetails,
        customerExtensionDetails,
        customerInfoDetails,
        extensionRingGroups,
        serviceFeatures,
    } = useSelector((state: ReduxState) => state.extensions);

    const lineSharing = useMemo(() => serviceFeatures?.find(
        (e) => e.name === ServiceFeatureName.LineSharing
    ), [serviceFeatures]);

    const initialValues: ExtensionFormType = useMemo(() => {
        if (!extension) return extensionFormDefaultValues;

        const recalculateCliFeautureFlag = () => {
            const cliFutureEnabled = getServiceFeatureValue(
                ServiceFeatureName.Cli,
                serviceFeatures
            ) !== 'N';

            const cliFeautureValue = getServiceFeatureValue(
                ServiceFeatureName.Cli,
                serviceFeatures,
                'centrex'
            );

            return cliFutureEnabled
                ? cliFeautureValue
                : '';
        };

        return {
            extensionName: extension?.name || '',
            extensionNumber: extension?.id || '',
            didNumber: extension?.numbers || [],
            displayNumber: recalculateCliFeautureFlag(),
            includeCorpDirectory: customerExtensionDetails?.published === 'Y',
            recordName: customerExtensionDetails?.prompt_name ?? '',
            recordFile: null,
            emailAddress: extension?.account_info?.email || '',
            timezone: extension?.account_info?.time_zone_name || '',
            portalLogin: extension?.account_info?.login || '',
            portalPassword: extension?.account_info?.password || '',
            country: extension?.account_info?.country || '',
            address: extension?.account_info?.baddr1 || '',
            city: extension?.account_info?.city || '',
            postalCode: extension?.account_info?.zip || '',
            state: extension?.account_info?.state || '',
            office:
                customerInfoDetails?.customer_info.i_office_type !== 1
                    ? customerInfoDetails?.customer_info.name || ''
                    : '',
            extensionRingGroups: extensionRingGroups || [],
            sharedLine: lineSharing?.effective_flag_value === YesNo.Yes,
            sharedLineLocked: lineSharing?.locked === 1,
        };
    }, [
        extension,
        mohDetails,
        customerInfoDetails,
        customerExtensionDetails,
        extensionRingGroups,
        serviceFeatures,
        lineSharing
    ]);

    return {
        initialValues,
        data: {
            mohItems: mohDetails?.items,
            didNumbers: customerInfoDetails?.didNumbers,
            accountId: extension?.account_info?.i_account,
            isBranchOffice: !customerExtensionDetails,
            apiErrors: extension?.apiError || apiErrors,
            subdivisionsList: subdivisionsList,
            passwordConfig: config,
            countriesList: countriesList,
            timeZonesList: timeZonesList,
        },
    };
};
