import React, { useCallback, useMemo, useState } from "react";
import {Permission} from "../../store/types/Permission";
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import Loader from "../../components/Loader/Loader";
import {useTranslation} from "react-i18next";
import {generateTabs} from "../../utils/generateTabs";
import {useRawPermissions} from "../../hooks/usePermissions";
import DetailsWrapper from "../../components/DetailsWraper/DetailsWrapper";
import SupervisorHeader from "../../components/DetailsHeaders/SupervisorHeader";
import RingGroupsTab from "../../components/Tabs/RingGroupsTab";
import AgentsTab from "../../components/Tabs/Agents/AgentsTab";
import usePageTitle from "../../hooks/usePageTitle";
import {actions} from "../../store";
import {useDispatch} from "react-redux";
import {RecentCalls} from "../CallHistory/RecentCalls/RecentCalls";
import {AgentActiveCalls} from "../CallHistory/ActiveCalls/AgentActiveCalls";
import {CallStatistics} from "../CallHistory/CallStatistics/CallStatistics";
import {useStyles} from "./utils";
import WebSocketDataProvider from "../../components/WebSocketDataProvider/WebSocketDataProvider";
import { SipCall } from "../../store/actions/ringgroups/payloads";
import Config from '../../config.json';

const SupervisorPanel = () => {

    const isLoading = false
    const classes = useStyles()
    const {t} = useTranslation()
    const permissions = useRawPermissions()
    const dispatch = useDispatch();
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

    usePageTitle();
    
    const hasWebSocket = useMemo(() => {
        if(!Config.WEBSOCKET_SERVER_URL?.length) {
            return false;
        }
        return true;
    }, [Config]);
    
    const getBasicData = useCallback(() => 
        {
            dispatch(actions.getCallHistoryBasicData.request({
                updateSipCalls: false
            }));
            dispatch(actions.getSipCallsList.request({}));
        }, []);

    const callStatusUpdateCallback = useCallback((call: SipCall) => {
        if(!call) {
            return;
        }
        if(!hasWebSocket) {
            dispatch(actions.updateSipCallStatusByWebSocketEvent.request(call));
        }
    }, [hasWebSocket]);
    
    const {tabNames, tabs, perm} = generateTabs(
        [
            {
                title: t('screens:ringGroups.ringGroups'),
                permission:
                Permission.SupervisorPanel.RingGroups.value,
                tab: <RingGroupsTab callback={getBasicData}/>,
            },
            {
                title: t('screens:supervisorPanel.agents'),
                permission:
                Permission.SupervisorPanel.Agents.value,
                tab: <AgentsTab callback={getBasicData}/>,
            },
            {
                title: t('screens:calls.currentCalls'),
                permission:
                Permission.SupervisorPanel.CurrentCalls.value,
                tab: <AgentActiveCalls callback={getBasicData}/>,
            },
            {
                title: t('screens:calls.recentCalls'),
                permission:
                Permission.SupervisorPanel.RecentCalls.value,
                tab: (
                    <RecentCalls
                        callback={getBasicData}
                        playCallRecordingPermission={Permission.SupervisorPanel.RecentCalls.PlayCallRecording.value}
                        callDetailRecordPermission={Permission.SupervisorPanel.RecentCalls.CallDetailRecord.value}
                        downloadCallRecordingPermission={Permission.SupervisorPanel.RecentCalls.DownloadCallRecording.value}
                        deleteCallRecordingPermission={Permission.SupervisorPanel.RecentCalls.CallDetailRecord.DeleteCallRecording.value}
                        downloadCallRecordingAtCallDetailPermission={Permission.SupervisorPanel.RecentCalls.CallDetailRecord.DownloadCallRecording.value}
                        downloadTranscriptionPermissionOnDetailsDialog={Permission.SupervisorPanel.RecentCalls.CallDetailRecord.DownloadCallTranscription.value}
                        downloadTranscriptionPermissionOnPayAudio={Permission.SupervisorPanel.RecentCalls.PlayCallRecording.DownloadCallTranscription.value}
                    />
                ),
            },
            {
                title: t('screens:calls.callStatistics'),
                permission:
                Permission.SupervisorPanel.CallStatistics.value,
                tab: (
                    <CallStatistics callback={getBasicData}/>
                ),
            }
        ],
        permissions,
    );

    const recalcCallsDurationEvent = useCallback(() => {
        const name = tabNames[currentTabIndex];
        if(name === t('screens:calls.currentCalls') || name === t('screens:supervisorPanel.agents')) {
            dispatch(actions.recalcSipCallsDuration.request());
        }
    }, [currentTabIndex, tabNames]);

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="supervisor-loader"/>
            </div>
        );
    }

    return (
        <PermissionPlaceholder
            permission={Permission.SupervisorPanel.value}
            allowedForUserGroups={Permission.SupervisorPanel.segmentedUserAccountTypes}
        >
            <div className={classes.mainContainer}>
                <DetailsWrapper
                    tabs={tabs}
                    tabsNames={tabNames}
                    top={<SupervisorHeader data-testid={'header-component'}/>}
                    defaultTabIndex={0}
                    perm={perm}
                    headerCustomClass={classes.customHeader}
                    doNotControlForms
                    onTabChange={(_, val) => {
                        setCurrentTabIndex(+val);
                    }}
                />
            </div>
            <WebSocketDataProvider callStatusUpdate={callStatusUpdateCallback} recalcCallsDurationEvent={recalcCallsDurationEvent} >
            </WebSocketDataProvider>
        </PermissionPlaceholder>
    )
}

export default SupervisorPanel