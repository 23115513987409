import {call, delay, put, select, takeLatest} from 'redux-saga/effects';

import {actions} from '../../index';
import {getConfigData, getLocalLanguagesList, getSessionData, getTimeZonesList,} from '../generic/saga';

import JSONFormData from '../../../utils/JSONFormData';
import {
    api,
    CHECK_AUTH_FAILD_CODE,
    CHECK_CSRF_TOKEN_INVALID_CODE,
    CHECK_CSRF_TOKEN_MISSING_CODE
} from '../../services/axios';
import {Account, Customer} from '../../../services/endpoints';
import {AxiosResponse} from 'axios';
import {CustomerIndividualInfoResponse, MyProfile,} from '../../actions/myProfile/payloads';
import {CustomerInfo} from '../../types/CustomerInfo';
import {
    makeMyProfileByCustomerIndividualInfo,
    makeMyProfileFromCustomerInfoDetails,
} from '../../../utils/transformers';
import {showErrorToast} from '../../../utils/showErrorToast';
import {ActionType} from 'typesafe-actions';
import {compareObjectsAndReturnDifferencesInValues} from '../../../utils/compareObjects';
import {EditMyProfileForm} from '../../../views/MyProfile/utils';
import qs from 'qs';
import {ReduxState} from '../../types';
import i18n from '../../../services/i18n';
import {ExtensionType} from "../../types/Extension";
import {SessionData} from "../../types/Session";
import {TimeZone} from "../../types/TimeZone";
import {ConfigData} from "../../types/ConfigData";

export function* getMyProfileDetails() {
    try {
        const sessionData: SessionData | undefined = yield select((state) => state.generic.sessionData);
        const timeZonesList: TimeZone[] = yield select((state) => state.generic.timeZonesList);
        const configData: ConfigData | undefined = yield select((state) => state.generic.configData);
        const customerCache: MyProfile | undefined = yield select((state) => state.myProfile.customer);

        if (!sessionData) {
            yield call(getSessionData);
        }

        if (!timeZonesList.length) {
            yield call(getTimeZonesList);
        }

        if (!configData) {
            yield call(getConfigData);
        }

        if (!customerCache) {
            yield call(getCustomerInfoByLogin);
        }

        yield call(getLocalLanguagesList);

        const customer: MyProfile | undefined = yield select((state) => state.myProfile.customer);


        let assigned_extension_id = undefined;
        let assigned_extension_account_id = undefined;
        if (customer?.type == 'hunt_group_supervisor') {

            const {session_id, csrf_token} = yield select((state) => state.auth);
            const body = new JSONFormData(session_id, csrf_token);
            body.setParams({
                i_account: customer.i_account,
                detailed_info: 1
            });

            const responseAccount: AxiosResponse<{ account_info: ExtensionType }> = yield api.post(
                Account.GetAccountInfo,
                body,
            );

            if (responseAccount.data.account_info.extension_id) {
                assigned_extension_id = responseAccount.data.account_info.extension_id;
            }
            if (responseAccount.data.account_info.id) {
                assigned_extension_account_id = responseAccount.data.account_info.id;
            }
        }


        yield put(actions.getMyProfileDetails.success({
            assigned_extension_id: assigned_extension_id, 
            assigned_extension_account_id: assigned_extension_account_id,
        }));
    } catch (e) {
        //@ts-ignore
        showErrorToast(e);
        yield put(actions.getMyProfileDetails.failure());
    }
}

export function* getCustomerInfoByLogin() {
    let customerDetails;
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);
        const {user, i_individual} = yield select((state) => state.generic.sessionData);


        if (i_individual) {
            const body = new JSONFormData(session_id, csrf_token);
            body.setParams({login: user});

            const customerIndividualInfo: AxiosResponse<CustomerIndividualInfoResponse> = yield api.post(
                Customer.GetCustomerIndividualInfo,
                body,
            );

            if(customerIndividualInfo.data?.customer_individual_info)
            {
                customerDetails = makeMyProfileByCustomerIndividualInfo(
                    customerIndividualInfo.data.customer_individual_info,
                );

                yield put(
                    actions.getMyProfileCustomerInfoByLogin.success(customerDetails),
                );
            }

        } else {
            const body = new JSONFormData(session_id, csrf_token);
            body.setParams({get_main_office_info: 1});
            const customerInfo: AxiosResponse<CustomerInfo> = yield api.post(
                Customer.GetCustomerInfo,
                body,
            );

            customerDetails = makeMyProfileFromCustomerInfoDetails(
                customerInfo.data.customer_info,
            );

            yield put(
                actions.getMyProfileCustomerInfoByLogin.success(customerDetails),
            );
        }

    } catch (e) {

        // @ts-ignore
        const errorCode = e?.response?.data.faultcode;
        const authErrors = [CHECK_AUTH_FAILD_CODE, CHECK_CSRF_TOKEN_INVALID_CODE, CHECK_CSRF_TOKEN_MISSING_CODE, CHECK_AUTH_FAILD_CODE]

        if (authErrors.includes(errorCode)) {
            yield put(actions.removeAuthDataAndReload({reload: true}));
            //@ts-ignore
            showErrorToast(e?.response?.data?.faultstring);
        }

        yield put(actions.getMyProfileCustomerInfoByLogin.failure());
    }
}

export function* editMyProfile(
    action: ActionType<typeof actions.editMyProfile.request>,
) {
    try {
        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editMyProfileSettings(dataToSave, action);
        yield editMyProfilePassword(dataToSave);

        yield put(actions.editMyProfile.success());

        if (dataToSave.email !== undefined) {
            localStorage.setItem('user_email', dataToSave.email);
        }

        showErrorToast(i18n.t<string>('screens:myProfile.profileUpdated'));

        yield delay(1000);
        location?.replace(
            `?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
    } catch (e: any) {
        if (e.response?.data?.faultstring != defaultPasswordError.response.data.faultstring) {
            //@ts-ignore
            showErrorToast(e.response?.data?.faultstring);
        }

        yield put(actions.editMyProfile.failure());
    }
}

const defaultPasswordError = {
    response: {
        data: {
            faultstring: i18n.t<string>(
                'errors:myCompany.passwordUpdatedFailed',
            ),
        },
    },
};

function* editMyProfilePassword(data: Partial<EditMyProfileForm>) {
    if (!data.oldPassword) return false;

    const payload = {
        old_password: data?.oldPassword || '',
        new_password: data?.newPassword || '',
        confirm_password: data?.confirmPassword || '',
    };

    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams(payload);
        const response: AxiosResponse<{
            success: number;
            errors?: { [key: string]: string };
        }> = yield api.post(Customer.ChangePassword, body);

        if (response.data.success !== 1) {
            throw response.data;
        }

        showErrorToast(i18n.t<string>('screens:extensions.passwordChanged'));
        yield delay(2000);

        yield put(actions.changePassword.success());
    } catch (e) {
        //@ts-ignore
        if (e.errors) {
            //@ts-ignore
            yield put(actions.changePassword.failure(e.errors));
            throw defaultPasswordError;
        } else {
            throw e;
        }
    }
}

function* editMyProfileSettings(
    data: Partial<EditMyProfileForm>,
    action: ActionType<typeof actions.editMyProfile.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const payload = {
        i_customer: action.payload.i_customer,
        login: data.login,
        email: data.email,
    };

    if (action.payload.i_individual == -1) {
        const payloadCustomerInfo: any = {
            i_ui_time_zone: undefined,
            i_lang: undefined,
        };

        if (data.timeZone !== undefined) {
            payloadCustomerInfo.i_ui_time_zone =
                data.timeZone?.i_time_zone ?? null;
        }

        if (data.language !== undefined) {
            payloadCustomerInfo.i_lang = data.language?.iso_639_1 ?? null;
        }

        body.setParams({
            customer_info: {
                ...payload,
                ...payloadCustomerInfo,
            },
        });

        yield api.post(Customer.UpdateCustomer, body);
    } else {
        const payloadCustomerInfo: any = {
            i_ui_time_zone: undefined,
            i_lang: undefined,
        };

        if (data.timeZone !== undefined) {
            payloadCustomerInfo.i_time_zone =
                data.timeZone?.i_time_zone ?? null;
        }

        if (data.language !== undefined) {
            payloadCustomerInfo.lang = data.language?.iso_639_1 ?? null;
        }

        body.setParams({
            customer_individual_info: {
                ...payload,
                i_individual: action.payload.i_individual,
                ...payloadCustomerInfo,
            },
        });

        yield api.post(Customer.UpdateCustomerIndividual, body);
    }

    yield call(getSessionData);
}

export const myProfileSaga = [
    takeLatest(actions.getMyProfileDetails.request, getMyProfileDetails),
    takeLatest(
        actions.getMyProfileCustomerInfoByLogin.request,
        getCustomerInfoByLogin,
    ),
    takeLatest(actions.editMyProfile.request, editMyProfile),
];
