import {Colors} from '../../styles/Colors';
import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({

    tooltipContainer: {
        minWidth: 284
    },

    mainContainer: {
        borderBottom: `1px solid ${Colors.Border}`,
        padding: '11px 5px',
        display: 'flex',
        alignItems: 'center',
        '&:last-of-type': {
            borderBottom: 'unset',
        },
    },

    mainContainerLast: {
        borderBottom: `unset`,
        padding: '11px 5px',
        display: 'flex',
        alignItems: 'center',
    },

    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    label: {
        color: Colors.Text2,
        fontWeight: 500,
        fontSize: 20,
    },
    mainProductLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${Colors.Secondary1}`,
        borderRadius: 2,
        color: Colors.Secondary1,
        fontSize: 10,
        textTransform: 'uppercase',
        width: 'fit-content',
        lineHeight: '12px',
        padding: '2px 8px',
        fontWeight: 400,
    },
    progressBar: {
        height: 16,
        borderRadius: 4,
        backgroundColor: Colors.Border,
        maxWidth: 180,

        '& .MuiLinearProgress-bar': {
            borderRadius: 4,
            backgroundColor: Colors.Secondary12,
        },
    },


    fullProgressBar: {
        '& .MuiLinearProgress-bar': {
            backgroundColor: Colors.Primary,
        },
    },


    bundleProgressBar: {
        '& .MuiLinearProgress-bar': {
            backgroundColor: Colors.Secondary12,
        },
    },
    unlimitedProgressBar: {
        '& .MuiLinearProgress-bar': {
            backgroundColor: Colors.Support,
            opacity: 0.3,
        },
    },
    usedQuantity: {
        color: Colors.Text,
        fontWeight: 400,
    },
    maxQuantity: {
        color: Colors.Gray12,
        fontWeight: 400
    },
    firstColumn: {
        width: '25%',
        paddingRight: 17,
    },
    secondColumn: {
        width: 100,
        paddingRight: 17,
        whiteSpace: 'nowrap'
    },
    thirdColumn: {
        display: 'flex',
        flex: 1,
        justifyContent: 'end'
    },

    smallMargin: {
        marginBottom: 0,
        lineHeight: '16px'
    }
}));
