import MoreMenu from '../MoreMenu/MoreMenu';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../styles/Colors';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import { showErrorToast } from '../../utils/showErrorToast';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

const useStyles = makeStyles(() => ({
    callTranscriptionContainer: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '16px',
        fontSize: '12px',
        color: Colors.Text3,
        '& > div': {
            marginBottom: 0,
        },
    },
}));

const TranscriptionAction: React.VFC<{
    callRecordingId: string,
}> = ({callRecordingId}) => {

    const {t} = useTranslation()
    const classes = useStyles()

    const permission = usePermissionContext();

    const readOnly =  permission === PermissionType.ReadOnly
    const hidden = permission === PermissionType.Hidden

    const dispatch = useDispatch();


    const handleDelete = () => {
        dispatch(actions.deleteTranscription.request({
            callRecordingId: callRecordingId, callback: () => {
                callRecordingId && dispatch(actions.checkIfTranscriptionExist.request({callRecordingId: callRecordingId}))
            }
        }))
    }

    const handleDownloadFile = () => {
        if (callRecordingId) {
            showErrorToast(t<string>('common:downloadWillStartShortly'));
            dispatch(actions.downloadTranscriptionFile.request({callRecordingId: callRecordingId}))
        }
    }

    return (
        <div className={classes.callTranscriptionContainer}>
            {t('screens:calls.callTranscription')}
            <MoreMenu hideMoreOptionsButton={false}>

                <MenuItem key="import-from-file"
                          onClick={handleDelete}
                >
                    {t('screens:calls.removeTranscription')}
                </MenuItem>

                {!hidden && (
                    <MenuItem key="upload-csv" disabled={readOnly} onClick={handleDownloadFile}>
                        {t('screens:calls.downloadTextFile')}
                    </MenuItem>
                )}

            </MoreMenu>
        </div>
    )
}

export default TranscriptionAction