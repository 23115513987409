import React from 'react';
import DoughnutChart from '../../components/Charts/DoughnutChart';
import {IconButton, makeStyles} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';
import {Colors} from '../../styles/Colors';
import {DoughnutChartProps} from '../Charts/doughnutChartUtils';
import {usePermissionContext} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    mainContainer: {
        borderRadius: 4,
        border: `1px solid ${Colors.Border}`,
        padding: '5px 20px 20px 20px',
        flex: 1,
        margin: 12,
        maxWidth: 'calc(33.3% - 26.7px)',
    },
    hideLegend: {
        padding: '0',
        border: "none",
        margin: '0px 12px'

    },
    widestMainContainer: {
        maxWidth: '50%',
    },
    widerMainContainer: {
        maxWidth: 'calc(50% - 40px)',
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 13,
        marginRight: -15,

        '&:hover $iconButton': {
            color: Colors.Border,
        },
    },
    label: {
        color: Colors.Secondary1,
        fontWeight: 500,
        fontSize: 16,
        cursor: 'pointer',

        '&:hover': {
            opacity: 0.7,
        },
    },
    iconButton: {
        '&:hover': {
            backgroundColor: Colors.SmokeBackground,
        },
    },
}));

export type DoughnutSectionProps = {
    label?: string;
    onPressLabel?: () => void;
    dataTestId?: string;
    dataQa?: string;
    accessDenied?: boolean;
    amountOfSectionsInParentContainer?: number;
    hideLabel?: boolean;
    hideLegend?: boolean,
    cutout?: string|number,
    icon?: any,
    hideLoadingLabelOnLoading?: boolean;
} & DoughnutChartProps;

const DoughnutSection: React.VFC<DoughnutSectionProps> = (
    {
        data,
        isLoading,
        label,
        onPressLabel,
        dataTestId,
        dataQa,
        accessDenied,
        amountOfSectionsInParentContainer,
        hideLabel = false,
        hideLegend = false,
        cutout,
        icon,
        hideLoadingLabelOnLoading,
        inLine= true,
        doNotAnimateValue = false,
    }) => {
    const classes = useStyles();
    const permission = usePermissionContext();

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <div
            className={classNames(classes.mainContainer,
                hideLegend && classes.hideLegend,
                (!hideLegend && amountOfSectionsInParentContainer === 1) && classes.widestMainContainer,
                (!hideLegend && amountOfSectionsInParentContainer === 2) && classes.widerMainContainer)}
            data-testid={dataTestId}
            data-qa={dataQa}
        >
            {!hideLabel && (
                <div className={classes.labelContainer}>
                    <a className={classes.label} onClick={onPressLabel}>
                        {label}
                    </a>
                    <IconButton
                        size="medium"
                        onClick={onPressLabel}
                        className={classes.iconButton}
                        data-testid="doughnut-section-navigate-button"
                        data-qa="doughnut-section-navigate-button"
                    >
                        <ChevronRight htmlColor={Colors.Secondary1}/>
                    </IconButton>
                </div>
            )}

            <DoughnutChart
                data={data}
                isLoading={isLoading}
                accessDenied={accessDenied}
                hideLegend={hideLegend}
                cutout={cutout}
                icon={icon}
                hideLoadingLabelOnLoading={hideLoadingLabelOnLoading}
                inLine={inLine}
                doNotAnimateValue={doNotAnimateValue}
            />
        </div>
    );
};

export default DoughnutSection;
