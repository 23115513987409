import React from 'react';
import {makeStyles} from "@material-ui/core";
import {Column} from 'react-table';
import {TFunction} from 'react-i18next';
import {CPRuleInfo, CPRuleInfoAction} from '../../store/types/CallScreening';
import {GetCPConditionListResponsePayload, OperationMode} from '../../store/actions/extensions/payloads';
import {ApiFileList} from '../../store/types/ApiFile';
import {Colors} from '../../styles/Colors';
import CustomizedIconButton from '../IconButton/IconButton';
import {Delete, Edit} from '@material-ui/icons';
import {translateBitMak} from '../../utils/extensions/translateBitMak';
import {Permission} from '../../store/types/Permission';
import Switch from '../Switch/Switch';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import classNames from 'classnames';
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import { NumberListItem } from '../../store/actions/callParkingSlots/payloads';
import CustomizedTooltip from '../Tooltip/Tooltip';

export const useStyles = makeStyles(() => ({
    rulesTableContainer: {
        width: 'calc(100% - 56px)',
        paddingRight: 32,
        paddingLeft: 24,
        marginTop: 2,
        background: 'transparent',
        border: 'none',
        overflowX: 'hidden',

        '& table': {
            background: 'transparent',
            border: 'none',
            overflowX: 'hidden',

            '& thead': {
                background: 'transparent',
                border: 'none',

                '& tr': {
                    '& > :first-child': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },

                    '& th[colspan="1"]:first-of-type': {
                        paddingLeft: 89,
                        marginRight: -60,
                        paddingRight: 0
                    },

                    '& td': {
                        paddingLeft: 4,
                        paddingRight: 4,
                    },

                    '& th': {
                        paddingRight: 4,
                        paddingLeft:4
                    }
                }
            },

            '& tbody': {
                background: 'transparent',
                border: 'none',

                '& tr': {
                    background: 'transparent',
                    border: 'none',

                    '& > :first-child': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },

                    '& td[aria-colindex="0"]': {
                        paddingLeft: 15,
                        paddingRight: 16,

                        '& > :first-child': {
                            marginRight: 16,
                        },
                    },

                    '& td': {
                        paddingLeft: 4,
                        paddingRight: 4,
                    }
                }
            }
        }
    },
    valueSpan: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: Colors.Text,
        maxHeight: '44px !important',
    },
    lastColumn: {
        color: Colors.Gray5
    },
    dragColumnStyle: {
        paddingLeft: 0,
        paddingRight: 0,
        width: 34,

        '& svg': {
            marginLeft: '14px !important',
            width: 19.5
        }
    },
    addRuleButton: {
        marginLeft: 8,
        marginTop: 8
    },
    listBarContainer: {
        overflow: 'hidden'
    },
    listBarItem: {
        width: 'fit-content',
        minWidth: 21,
        height: 12,
        padding: '5px 8px 4px 8px',
        gap: 10,
        border: '1px solid rgba(245, 132, 31, 1)',
        borderRadius: 2,
        textTransform: 'uppercase',
        color: 'rgba(245, 132, 31, 1)',
        background: 'transparent',
        fontSize: 10,
        lineHeight: '12px',
        fontWeight: 400,
        marginBottom: 4,
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    numberList?: NumberListItem[],
    conditions?: GetCPConditionListResponsePayload,
    cpOperationModeList?: OperationMode[],
    customerResponseMessages?: ApiFileList,
    onEdit?: (object: CPRuleInfo) => void,
    onDelete?: (object: CPRuleInfo) => void,
    onSwitchClick?: (value: boolean, object: CPRuleInfo) => void
): Column<CPRuleInfo>[] => {

    const defActions = Object.values(CPRuleInfoAction)
        .filter((v) => Number.isInteger(v))
        .map((v) => ({
            name: t(`enums:callScreeningActionEnum.${v}`),
            value: v,
        }));

    const drawValueSpan = (text?: string, customClass?: string) => {
        return (
            <div style={{overflow: 'hidden'}}>
                <OverflowTooltip
                    tooltip={text}
                    text={text}
                    classes={{text: classNames(classes.valueSpan, customClass)}}
                    copy={false}
                />
            </div>
        );
    }
    
    const drawListIcon = (item: NumberListItem) => {
        const titleText = t('tooltips:callSettings.theRuleUses', {
            name: item.name,
            value: item.total_numbers ?? 0
        });
        return (
            <CustomizedTooltip
                title={titleText}
                interactive
                disableHoverListener={false}
                copy={false}
            >
                <div className={classes.listBarContainer}>
                    <div className={classes.listBarItem}>
                        {t('screens:callSettings.list')}
                    </div>
                    <div>
                        <OverflowTooltip
                            tooltip={titleText}
                            text={item.name}
                        />
                    </div>
                </div>
            </CustomizedTooltip>
        );
    }

    const cpConditionValue = (cpId: number | null | undefined, addNumbers?: boolean) => {
        if (cpId) {
            const item = conditions?.cp_condition_list?.find(e => e.i_cp_condition === cpId);
            const numb = numberList?.find(e=>e.i_phone_number_list === cpId);
            if(addNumbers && numb) {
                return drawListIcon(numb);
            }
            else if (item) {
                return drawValueSpan(item.name);
            }
        }
        return drawValueSpan(t('common:any'));
    }

    const cpModeValue = (cpId: number | null | undefined) => {
        let value = t('common:any');
        if (cpId) {
            value = translateBitMak(cpId, cpOperationModeList || [])?.translation;
        }

        return drawValueSpan(value);
    }

    const cpResponseMessageValue = (cpId: number | null | undefined) => {
        if (cpId) {
            const item = customerResponseMessages?.file_list?.find(e => e.id === cpId);
            if (item) {
                return drawValueSpan(item.name, classes.lastColumn);
            }
        }
        return drawValueSpan(t('common:notSet'), classes.lastColumn);
    }

    const columns: Column<CPRuleInfo>[] = [
        {
            Header: t<string>('common:from'),
            accessor: 'from_number_i_cp_condition',
            width: 2,
            Cell: function Cell(params) {
                const from = params.row.original.from_number_i_cp_condition;
                return (
                    <>
                        <PermissionProvider permission={Permission.Calls.Settings.CallScreening.value}>
                            <Switch
                                checked={params.row.original.enabled === 'Y'}
                                onChange={(event) => {
                                    onSwitchClick?.(event.target.checked, params.row.original);
                                }}
                                dataQa={'extension-details-status-switcher' + params.row.original.i_cpp_rule}
                                dataTestId={'extension-details-status-switcher' + params.row.original.i_cpp_rule}
                            />
                            {cpConditionValue(from, true)}
                        </PermissionProvider>
                    </>
                );
            },
        },
        {
            Header: t<string>('common:to'),
            accessor: 'to_number_i_cp_condition',
            width: 1,
            Cell: function Cell(params) {
                const to = params.row.original.to_number_i_cp_condition;
                return cpConditionValue(to);
            },
        },
        {
            Header: t<string>('common:period'),
            accessor: 'time_window_i_cp_condition',
            width: 1,
            Cell: function Cell(params) {
                const period = params.row.original.time_window_i_cp_condition;
                return cpConditionValue(period);
            },
        },
        {
            Header: t<string>('screens:callSettings.mode'),
            accessor: 'operation_mode',
            width: 1,
            Cell: function Cell(params) {
                const mode = params.row.original.operation_mode;
                return cpModeValue(mode);
            },
        },
        {
            Header: t<string>('screens:callSettings.action'),
            accessor: 'action',
            width: 1,
            Cell: function Cell(params) {
                const action = params.row.original.action;
                const selected = defActions.find((v) => v.value == action)?.name;
                return drawValueSpan(selected);
            }
        },
        {
            Header: t<string>('screens:callSettings.responseMessage'),
            accessor: 'i_response_message',
            width: 1,
            Cell: function Cell(params) {
                const responseAction = params.row.original.i_response_message;
                return cpResponseMessageValue(responseAction);
            },
        },
        {
            Header: '',
            accessor: 'response_message_name',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (<>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original);
                        }}
                        dataTestId="edit-rule-item-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    <CustomizedIconButton
                        onClick={() => {
                            onDelete?.(original);
                        }}
                        dataTestId="remove-rule-item-button"
                        tooltipText={t<string>('common:delete')}
                    >
                        <Delete htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                </>);
            },
        }
    ];

    return columns;
};