import {useSelector} from "react-redux";
import {APIErrorInterface, ReduxState} from "../store/types";
import {PortalPermission} from "../store/types/Permission";
import {CounterInterface, CustomerAgreementCondition} from "../store/types/CustomerAgreementCondition";
import {CustomerOfficeType} from "../store/types/CustomerInfo";
import {useMemo} from "react";
import {DashboardViewData} from "../views/Dashboard/utils";
import {SipCallState} from "../store/actions/ringgroups/payloads";

export const useDashboardData = () => {

    const accessDeniedToDidNumbers = useSelector<ReduxState, boolean | undefined>(
        (state) => state.dashboard.accessDeniedToDidNumbers,
    );

    const registeredExtensions = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.registeredExtensions,
    );
    const unregisteredExtensions = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.unRegisteredExtensions,
    );
    const sipCallsApiError = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.ringgroups?.sipCallsApiError
    );
    const activeCalls = useSelector<ReduxState, number | undefined>(
        (state) => state.ringgroups?.sipCallsList?.filter(e=>e.state === SipCallState.Connected)?.length ?? 0
    );
    const callsFromLastDays = useSelector<ReduxState,
        { date: string; value: number }[] | undefined>((state) => state.dashboard.callsFromLastDays);

    const inUseDidNumbers = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.inUseDidNumbers,
    );
    const notInUseDidNumbers = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.notInUseDidNumbers,
    );
    const inUseDevices = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.inUseDevices,
    );
    const notInUseDevices = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.notInUseDevices,
    );
    const permissions = useSelector<ReduxState, PortalPermission | undefined>(
        (state) => state.permissions?.permissions,
    );

    const mainProducts = useSelector<ReduxState,
        CustomerAgreementCondition[] | undefined>((state) => state.dashboard.mainProducts);

    const bundles = useSelector<ReduxState,
        CounterInterface[] | undefined>((state) => state.dashboard.bundles);

    const addonProducts = useSelector<ReduxState,
        CustomerAgreementCondition[] | undefined>((state) => state.dashboard.addonProducts);

    const outDateFormat = useSelector<ReduxState, string | undefined>(
        (state) => state.generic.sessionData?.out_date_format,
    );

    const i_office_type: CustomerOfficeType = useSelector<ReduxState, CustomerOfficeType>(
        (state) => state.generic?.customerOfficeType || CustomerOfficeType.Hq
    );

    const data: DashboardViewData = useMemo(() => {
            return {
                registeredExtensions,
                unregisteredExtensions,
                activeCalls,
                inUseDidNumbers,
                callsFromLastDays,
                notInUseDidNumbers,
                inUseDevices,
                notInUseDevices,
                permissions,
                mainProducts,
                addonProducts,
                outDateFormat,
                i_office_type,
                accessDeniedToDidNumbers,
                sipCallsApiError,
                bundles
            }
        }, [
            registeredExtensions,
            unregisteredExtensions,
            activeCalls,
            inUseDidNumbers,
            callsFromLastDays,
            notInUseDidNumbers,
            inUseDevices,
            notInUseDevices,
            permissions,
            mainProducts,
            addonProducts,
            outDateFormat,
            i_office_type,
            accessDeniedToDidNumbers,
            sipCallsApiError
        ]
    );

    return {
        ...data
    }
}