export interface OwnerPaymentMethod {
    icon_url: string;
    web_link: string;
    remote_cc_storage: string;
    refnum?: string;
    i_online_payment_processor: number;
    ext_auth: string;
    i_payment_method: number;
    processor: string;
    recurring: string;
    csc_policy?: string;
    test_mode: string;
    url_suffix?: string;
    login: string;
    payment_method: PaymentMethod;
    handler?: string;
    min_allowed_payment: number;
    callback?: string;
    merchant_account_name: string;
}

export interface PaymentMethodInfo {
    iso_3166_1_a2: string;
    number: string;
    account_number: string;
    refnum: string;
    city: string;
    i_country_subdivision: number;
    exp_month: string;
    i_payment_method: number;
    address: string;
    start_date: string;
    token: string;
    bank_number: string;
    available_for_refund: number;
    issue_no: string;
    name: string;
    exp_year: string;
    phone_number: string;
    number_hash: string;
    payment_method: PaymentMethod;
    zip: string;
    csc_required: number;
    cvv: string;
}

export interface AutoPaymentInfo {
    frozen: string;
    object: string;
    pay_amount: number;
    i_object: number;
    balance_threshold: number;
}

export enum PaymentMethod {
    Visa = 'VISA',
    AmericanExpress = 'American Express',
    BankAccount = 'check',
    DinersClub = 'diners_club',
    Discover = 'Discover',
    Echeck = 'check',
    Maestro = 'Maestro',
    Mastercard = 'MasterCard',
    Paypal = 'PayPal',
    DirectDebitNL = 'DirectDebitNL',
    PayNearMe = 'PayNearMe',
    Luottokunta = 'Luottokunta',
    PayArena = 'Payarena',
    Remita = 'Remita',
    MoneyBookers = 'Moneybookers',
    VCS = 'VCS',
    WorldPay = 'WorldPayJunior'
}

export enum PaymentStatus {
    Completed = 'COMPLETED',
    Pending = 'STARTED',
    Failed = 'FAILED',
    Authorized = 'AUTHORIZED',
}

export interface TaxesResponse {
    warnings: string[];
    taxes_list: {
        amount: number;
        level_name: string;
        name: string;
        type: string;
    }[];
}

export interface PaymentDetails {
    amount: number;
    cc_number_hash: string;
    cdrid: string;
    details: string;
    i_customer: string;
    i_merchant_account: string;
    i_online_payment_processor: string;
    i_payment_method: string;
    i_payment_transaction: string;
    i_payment_transaction_type: string;
    iso_4217: string;
    payment_method_info: string;
    result_message: string;
    secret: string;
    status: PaymentStatus;
    test_mode: string;
    timestamp: string;
    x_transaction_id: string;
    taxAmount?: string;
    paymentAmount?: string;
}

export interface RegisterTransaction {
    amount: number,
    i_owner?: number,
    i_payment_method: number,
    owner_type?: 'Customer' | 'Account',
    purpose?: string,
    purpose_details?: string,
    i_online_payment_processor?: number,
    callback?: () => void
}