import React from 'react';
import {CustomerAgreementCondition} from '../../store/types/CustomerAgreementCondition';
import ProductRow from './ProductRow';
import DashboardSection from "../../layouts/DashboardSection";
import {useTranslation} from "react-i18next";


export type ProductsSectionProps = {
    products?: CustomerAgreementCondition[];
    addonProducts?: CustomerAgreementCondition[];
};

const ProductsSection: React.VFC<ProductsSectionProps> = (
    {
        products,
        addonProducts
    }) => {


    const {t} = useTranslation()

    const productSectionIsLoading = (): boolean => {
        return products === undefined || addonProducts === undefined
    }

    const notDataForProductSection = (): boolean => {

        const productDataExist = (products || [])?.concat(
            addonProducts || [],
        ).length === 0

        return productDataExist && !productSectionIsLoading()
    }

    const data = (products || [])?.concat(addonProducts || [],)

    return (
        <DashboardSection
            title={t('screens:dashboard.licensing')}
            noIsVisible={notDataForProductSection()}
            isLoading={productSectionIsLoading()}
            noDataMsg={t('screens:dashboard.noProductsAssignedToCompany')}
        >
            {data?.map((v) => (
                <ProductRow key={v.i_product} product={v}/>
            ))}
        </DashboardSection>
    )

};

export default ProductsSection;
