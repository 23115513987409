import React from 'react';
import {
    CPRuleInfoDetails,
    YesNo,
} from '../../../../store/types/CallScreening';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import CallScreeningAction from '../../../CallScreeningAction/CallScreeningAction';
import {makeStyles} from '@material-ui/core/styles';
import PolicyComponent from '../../../CallScreeningAction/PolicyComponent';
import CustomizedSwitch from '../../../Switch/Switch';
import OverflowTooltip from '../../../OverflowTooltip/OverflowTooltip';
import {ExtendGetCallProcessingPolicyListEntity} from '../../../../store/actions/extensions/payloads';
import {Colors} from '../../../../styles/Colors';
import TimeFilterCell from '../../../Calls/TimeFilterCell';
import CpNumbersCell from '../../../Calls/CpNumbersCell';
import CustomizedIconButton from '../../../IconButton/IconButton';
import {Delete} from '@material-ui/icons';
import { NumberListItem } from '../../../../store/actions/callParkingSlots/payloads';
import CustomizedTooltip from '../../../Tooltip/Tooltip';

export type CallScreeningFormType = {
    callScreeningLocked: boolean;
    callScreening: boolean;
    pbxEnabled: boolean;
    rules?: CPRuleInfoDetails[];
    notSelectedPolicy?: ExtendGetCallProcessingPolicyListEntity[];
};

export const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 1040,
        paddingLeft: 16,
    },

    noRules: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        color: Colors.Gray5,
        marginTop: 40,
        display: 'flex',
    },
    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '336px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
    },

    contentDialog: {
        backgroundColor: Colors.White + ' !important',
    },
    excl: {
        color: Colors.White,
    },

    table: {
        backgroundColor: 'transparent !important',
        background: 'transparent !important',

        '& td.MuiTableCell-root.MuiTableCell-body': {
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 400,
            alignItems: 'center',
        },

        '& thead th': {
            padding: '16px 8px',
        },

        '& thead th:nth-child(2)': {
            padding: '16px 8px 16px 0px !important',
            minWidth: '80px !important',
            maxWidth: '80px !important',
        },

        '& tbody td': {
            padding: '16px 8px 16px 8px',
        },

        '& tbody tr td:nth-child(2)': {
            padding: '16px 8px 16px 0px',
            minWidth: '80px !important',
            maxWidth: '80px !important',
        },

        '& thead, & tr': {
            backgroundColor: 'unset',
            background: 'unset',
        },
    },

    disabled: {
        '& tbody tr td:nth-child(2)': {
            padding: '16px 8px 16px 0px !important',
            minWidth: '170px !important',
            maxWidth: '170px',
        },

        '& thead th:nth-child(2)': {
            padding: '16px 8px !important',
            minWidth: '170px !important',
            maxWidth: '170px !important',
        },
    },

    disabledNotUsedPolicy: {
        '& tbody tr > td:nth-child(1)': {
            display: 'none !important'
        },

        '& thead th:nth-child(1)': {
            display: 'none !important'
        },

        '& tbody tr td:nth-child(2)': {
            paddingLeft: 12,
            minWidth: '102px !important',
            maxWidth: '102px',
        },
        '& tbody tr td:nth-child(3)': {
            paddingLeft: 0,
        },
    },

    dragColumnStyle: {
        width: 40,
        padding: 0 + '!important',
        '& svg': {
            marginLeft: 0 + '!important',
        },
    },

    dragHeaderStyle: {
        width: 40,
        paddingRight: 0 + '!important',
        paddingLeft: 0 + '!important',
    },

    textOverflow: {
        '-webkit-line-clamp': 2,
        position: 'relative',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        maxHeight: '40px',
        whiteSpace: 'normal',
        lineHeight: '20px',
    },

    tableContainer: {
        backgroundColor: 'unset',
        background: 'unset',
        border: 'none',
    },

    rulesTable: {
        '& tbody tr td:last-child': {
            padding: '16px 8px 16px 8px',
        },
        overflowX: 'hidden'
    },

    tablePolicyContainer: {
        '& tr>td:first-child.MuiTableCell-root': {
            padding: 0,
        },
    },

    pbx: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '18.75px',
        color: Colors.Gray7,
        marginBottom: 10,
        marginTop: 16,
    },

    swicher: {
        marginBottom: 2,
        paddingTop: 22,
        height: 'auto',
        color: Colors.Gray7,
    },

    action: {
        justifyContent: 'center',
        display: 'flex',
        flex: 1,
    },
    
    listBarContainer: {
        overflow: 'hidden'
    },

    listBarItem: {
        width: 'fit-content',
        minWidth: 21,
        height: 12,
        padding: '5px 8px 4px 8px',
        gap: 10,
        border: '1px solid rgba(245, 132, 31, 1)',
        borderRadius: 2,
        textTransform: 'uppercase',
        color: 'rgba(245, 132, 31, 1)',
        background: 'transparent',
        fontSize: 10,
        lineHeight: '12px',
        fontWeight: 400,
        marginBottom: 4,
    }
}));

export const generateRulesColumns = (
    t: TFunction<string>,
    deleteRule?: (rule_id: string, i_cp_rule?: number) => void,
    switchState?: (rule_id: string) => void,
    classes?: ReturnType<typeof useStyles>,
    numberList?: NumberListItem[]
): Column<CPRuleInfoDetails>[] => {
    return [
        {
            Header: (
                <div style={{marginLeft: 10}}>
                    {t<string>('screens:extensions.active')}
                </div>
            ),
            accessor: 'i_cp_rule',
            width: 1,
            Cell: function Cell(params) {
                const testSelector = `rule-active-switcher-${params.row.original.i_cp_rule}`;

                return (
                    <CustomizedSwitch
                        checked={params.row.original.is_active == YesNo.Yes}
                        dataQa={testSelector}
                        dataTestId={testSelector}
                        onChange={() =>
                            params.row.original.id &&
                            switchState?.(params.row.original.id)
                        }
                    />
                );
            },
        },
        {
            Header: t<string>('screens:extensions.from'),
            accessor: 'from_number_i_cp_condition',
            width: 2,
            Cell: function Cell(params) {
                const globalNumber = numberList?.find(e => 
                    e.i_phone_number_list === params.row.original.from_number_i_cp_condition);
                if(params.row.original.from_number_i_cp_condition && globalNumber)
                {
                    const titleText = t('tooltips:callSettings.theRuleUses', {
                        name: globalNumber.name,
                        value: globalNumber.total_numbers ?? 0
                    });
                    return (
                        <CustomizedTooltip
                            title={titleText}
                            interactive
                            disableHoverListener={false}
                            copy={false}
                        >
                            <div className={classes?.listBarContainer}>
                                <div className={classes?.listBarItem}>
                                    {t('screens:callSettings.list')}
                                </div>
                                <div>
                                    <OverflowTooltip
                                        tooltip={titleText}
                                        text={globalNumber.name}
                                    />
                                </div>
                            </div>
                        </CustomizedTooltip>
                    );
                }

                return !params.row.original.showOnlyPolicy ? (
                    <OverflowTooltip
                        classes={{text: classes?.textOverflow}}
                        dataQa="call-screening-from-filter"
                        text={
                            <CpNumbersCell
                                cpNumbers={params.row.original.FromNumber}
                            />
                        }
                        tooltip={
                            <CpNumbersCell
                                cpNumbers={params.row.original.FromNumber}
                                exclClass={classes?.excl}
                            />
                        }
                        copy={false}
                    />
                ) : (
                    <PolicyComponent
                        name={params.row.original.policyDetails?.name}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:extensions.to'),
            accessor: 'to_number_i_cp_condition',
            width: 2,
            Cell: function Cell(params) {
                return !params.row.original.showOnlyPolicy ? (
                    <OverflowTooltip
                        dataQa="call-screening-to-filter"
                        text={
                            <CpNumbersCell
                                cpNumbers={params.row.original.ToNumber}
                            />
                        }
                        tooltip={
                            <CpNumbersCell
                                cpNumbers={params.row.original.ToNumber}
                                exclClass={classes?.excl}
                            />
                        }
                        copy={false}
                    />
                ) : (
                    ''
                );
            },
        },
        {
            Header: t<string>('screens:extensions.time'),
            accessor: 'time_window_i_cp_condition',
            width: 2,
            Cell: function Cell(params) {
                const period = params.row.original.TimeWindow?.time_window?.period ?? '';
                return (
                    <TimeFilterCell
                        period={period}
                        allowShowAny
                        showOnlyPolicy={params.row.original.showOnlyPolicy}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:extensions.mode'),
            accessor: 'i_account',
            width: 2,
            Cell: function Cell(params) {
                const modes = params.row.original.modeDecode?.translation;
                return !params.row.original.showOnlyPolicy ? (
                    <OverflowTooltip
                        classes={{text: classes?.textOverflow}}
                        dataQa="call-screening-modes"
                        text={modes}
                        tooltip={modes}
                        copy={false}
                    />
                ) : (
                    ''
                );
            },
        },
        {
            Header: t<string>('screens:extensions.action'),
            accessor: 'action',
            width: 1.5,
            Cell: function Cell(params) {
                const {
                    action,
                    policyDetails,
                    response_message_name,
                } = params.row.original;

                if (params.row.original.showOnlyPolicy) {
                    return '';
                } else {
                    return (
                        <CallScreeningAction
                            action={t(
                                `enums:callScreeningActionEnum.${action}`,
                            )}
                            policy={policyDetails?.name}
                            responseMessage={response_message_name}
                        />
                    );
                }
            },
        },
        {
            accessor: 'id',
            width: 0.5,
            Cell: function Cell(params) {
                return !params.row.original.showOnlyPolicy ? (
                    <div className={classes?.action}>
                        <CustomizedIconButton
                            onClick={() =>
                                params.row.original.id &&
                                deleteRule?.(
                                    params.row.original.id,
                                    params.row.original?.i_cp_rule || undefined,
                                )
                            }
                            tooltipText={t<string>('common:delete')}
                            dataTestId="remove-list-item-button"
                            dataQa="remove-list-item-button"
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    </div>
                ) : (
                    ''
                );
            },
        },
    ];
};

export const generatePolicyColumns = (
    t: TFunction<string>,
    switchState?: (rule_id: number) => void,
): Column<ExtendGetCallProcessingPolicyListEntity>[] => {
    return [
        {
            Header: 'empty',
            width: 1,
            maxWidth: 40,
        },
        {
            Header: 'status',
            accessor: 'i_cp_policy',
            width: 1,
            maxWidth: 80,
            minWidth: 80,
            Cell: function Cell(params) {
                const testSelector = `rule-active-switcher-${params.row.original.i_cp_policy}`;

                return (
                    <CustomizedSwitch
                        checked={params.row.original.active == true}
                        dataQa={testSelector}
                        dataTestId={testSelector}
                        onChange={() =>
                            params.row.original.i_cp_policy &&
                            switchState?.(params.row.original.i_cp_policy)
                        }
                    />
                );
            },
        },
        {
            Header: 'name',
            accessor: 'name',
            width: 1,
            Cell: function Cell(params) {
                return <PolicyComponent name={params.row.original.name}/>;
            },
        },
        {
            Header: t<string>('screens:extensions.from'),
            width: 1,
        },
        {
            Header: t<string>('screens:extensions.to'),
            width: 1,
        },
        {
            Header: t<string>('screens:extensions.time'),
            width: 1,
        },
        {
            Header: t<string>('screens:extensions.action'),
            width: 0.5,
        },
        {
            Header: 'id',
            width: 0.5,
        },
    ];
};
