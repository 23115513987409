import React, {useCallback, useEffect, useState} from 'react';
import DeviceTitle from '../../components/Devices/DeviceTitle';
import DeviceInfo from '../../components/Devices/DeviceInfo';
import history from '../../history';
import PortsConfigList from '../../components/Devices/PortsConfigList';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import {UAInfo} from '../../store/types/Devices';
import Loader from '../../components/Loader/Loader';
import {actions} from '../../store';
import {Routes} from '../../routes/routes';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission, PermissionType} from "../../store/types/Permission";
import PortedDeviceInfoForm from '../../components/Devices/PortedDeviceInfoForm';
import classNames from 'classnames';
import FormActions from '../../components/Forms/FormActions';
import {usePermissions} from '../../hooks/usePermissions';
import {useTranslation} from 'react-i18next';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import {macAddressLengthFunc, macAddressLengthFuncForMask, MacAddressPlaceHolder} from './NewDeviceDialog.utils';
import PreventLeavingPage from '../../components/AlertDialog/PreventLeavingPage';
import {DeviceDetailsProps, useStyles} from './DeviceDetails.utils';
import NotFound from '../NotFound/NotFound';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {getFreeLines} from "./FreeLines";
import {showErrorToast} from "../../utils/showErrorToast";

const DeviceDetails: React.FC<DeviceDetailsProps> = ({id}) => {
    const {t} = useTranslation();
    usePageTitle();
    const dispatch = useDispatch();
    const classes = useStyles();
    const editPagePermission = usePermissions(...Permission.Inventory.Devices.DeviceDetails.value);

    const {items, isLoading, isReleasingDevice, isEditing} = useSelector(
        (state: ReduxState) => state.devices,
    );

    const [showLoader, setShowLoader] = useState(false)
    const [device, setDevice] = useState<UAInfo | undefined>();
    const [form, setForm] = useState<UAInfo | undefined>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);
    const [isSetDeviceComplete, setIsSetDeviceComplete] = useState<boolean>(false);
    const [invalidDeviceId, setInvalidDeviceId] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        i_ua?: number;
        name?: string;
    }>({
        isOpen: false
    });

    useEffect(() => {
        if (id && items == undefined) {
            dispatch(
                actions.devicesList.request({
                    i_ua: id,
                    with_available_ports: 1,
                    with_busy_ports: 1,
                })
            );
        }
    }, [id, items]);

    useEffect(() => {
        if (items) {
            const selected = items?.find((o) => o.i_ua == id);
            selected && setDevice(selected);

            if (selected == undefined) {
                setInvalidDeviceId(true);
            }
            setIsSetDeviceComplete(true);
        }
    }, [items]);

    const onChevronClick = () => {
        history.push(Routes.Devices);
    };

    const handleDeleteAction = () => {
        dispatch(actions.deleteUA.request({
                i_ua: deleteDialog.i_ua || 0,
                callback: () => {
                    closeDeleteDialog();
                    showErrorToast(t<string>('screens:devices.deviceDeleted'));
                    //@ts-ignore
                    window.history.replaceState(null, null, '/');
                    history.push(Routes.Devices);
                }
            }
        ));
        setIsDirty(false);
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            i_ua: undefined,
            name: undefined
        });
    };

    const handleUpdateAction = useCallback((returnToList: boolean) => {
        setShowLoader(true);
        if (!form) {
            setShowLoader(false);
            return;
        }
        dispatch(actions.updateUa.request({
            object: form,
            callback: () => {
                showErrorToast(t<string>('screens:devices.deviceUpdated'));
                setShowLoader(false);

                if (returnToList) {
                    window.history.replaceState(null, '', '/');
                    history.push(Routes.Devices);
                } else {
                    dispatch(
                        actions.devicesList.request({
                            i_ua: id,
                            with_available_ports: 1,
                            with_busy_ports: 1,
                        }),
                    );
                }
            },
            callbackOnError: () => {
                setShowLoader(false);
            }
        }));
    }, [form]);

    const portedDeviceOnSave = useCallback((isValid: boolean, returnToList = false) => {
        setValidate(false);
        if (isValid) handleUpdateAction(returnToList);
    }, [setValidate, handleUpdateAction]);

    if ((isLoading && !isReleasingDevice && !isEditing && items == undefined) && !isSetDeviceComplete) {
        return (
            <PermissionPlaceholder permission={Permission.Inventory.Devices.DeviceDetails.value}>
                <div className={classes.loader}>
                    <Loader dataQa={'device-details-loader'}/>
                </div>
            </PermissionPlaceholder>)
    }

    let allLinesFree = false;
    if (device) {
        const freeLines = getFreeLines(device);
        allLinesFree = freeLines.left == freeLines.right;
    }

    return !isLoading && invalidDeviceId ? (
        <NotFound/>
    ) : (
        <PermissionProvider permission={Permission.Inventory.Devices.DeviceDetails.value}>
            <div className={classes.root}>
                {(showLoader) && (
                    <Loader
                        dataQa="edit-ring-device-loader"
                        absolutePosition
                    />
                )}
                <div className={classes.scrollable}>
                    <DeviceTitle
                        device={device}
                        className={classes.deviceHeader}
                        onChevronClick={onChevronClick}
                    />
                    <div className={classNames(classes.contentDevice, device?.ported === 'Y'
                        && classes.extraBottomPadding)}
                    >
                        {device?.ported === 'Y' ? (
                            <PortedDeviceInfoForm
                                setFormValues={setForm}
                                onSubmitValidation={validate}
                                setDirty={setIsDirty}
                                setIsFormValid={(v) => {
                                    portedDeviceOnSave(v, false);
                                }}
                                className={classes.deviceInfo}
                                device={device}
                            />
                        ) : (
                            <DeviceInfo
                                className={classes.deviceInfo}
                                device={device}
                                showAsciiValue
                            />
                        )}
                        <PortsConfigList
                            device={device}
                            className={classes.devicePorts}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                {device?.ported === 'Y' && editPagePermission !== PermissionType.Hidden && (
                    <FormActions
                        isSaveActive={isDirty &&
                            macAddressLengthFunc(form?.mac) === macAddressLengthFuncForMask(MacAddressPlaceHolder) &&
                            (form?.name?.length || 0) > 0
                        }
                        onSave={() => {
                            if(!validate) {
                                setValidate(true);
                            }
                        }}
                        hideCancel={false}
                        hideDelete={!allLinesFree}
                        editPermission={Permission.Inventory.Devices.DeviceDetails.value}
                        deleteTitle={t('screens:devices.deleteDevice')}
                        onDelete={() => {
                            if (!form) {
                                return;
                            }
                            setDeleteDialog({isOpen: true, i_ua: form.i_ua, name: form.name});
                        }}
                        deleteButtonDisabled={!allLinesFree}
                        deletePermission={Permission.Inventory.Devices.DeviceDetails.DeleteDevice.value}
                        onCancel={onChevronClick}
                    />)}
                <AlertDialog
                    className={classes.deleteDialog}
                    contentClass={classes.contentDialog}
                    isOpen={deleteDialog.isOpen}
                    dataQa={'delete-rule-dialog'}
                    hideHeader
                    description={t('screens:devices.deleteRule', {
                        name: deleteDialog.name || '',
                    })}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={closeDeleteDialog}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={handleDeleteAction}
                        />,
                    ]}
                />
                <PreventLeavingPage
                    isOpen={isDirty}
                    onSave={() => {
                        if(!validate) {
                            portedDeviceOnSave(true, true);
                        }
                    }}
                />
            </div>
        </PermissionProvider>
    );
};

export default DeviceDetails;
