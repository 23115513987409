import React from "react";
import EmptyGridPlaceholder from "../components/DataGrid/EmptyGridPlaceholder";
import ProductLoadingPlaceholder from "../components/Dashboard/ProductLoadingPlaceholder";
import {Fade, makeStyles} from "@material-ui/core";
import {Colors} from "../styles/Colors";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
    mainContainer: {
        borderRadius: 4,
        border: `1px solid ${Colors.Border}`,
        padding: 22,
        marginBottom: 24,
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    label: {
        color: Colors.Text2,
        fontWeight: 500,
        fontSize: 20,
    },
}));

interface DashboardSectionInterface {
    title: string
    noIsVisible: boolean
    isLoading: boolean
    noDataMsg: string,
    mainContainerCustomClass?: string
}

const DashboardSection: React.FC<DashboardSectionInterface> = (
    {
        noIsVisible,
        title,
        isLoading,
        noDataMsg,
        mainContainerCustomClass,
        children
    }) => {

    const classes = useStyles();

    return (
        <div className={classNames(classes.mainContainer, mainContainerCustomClass)}>

            <div className={classes.labelContainer}>
                <span className={classes.label}>
                   {title}
                </span>
            </div>

            <Fade in={!isLoading} timeout={2000} appear={false}>

                <div>
                    {children}
                </div>

            </Fade>

            <EmptyGridPlaceholder
                message={noDataMsg}
                isVisible={noIsVisible}
            />

            <ProductLoadingPlaceholder isVisible={isLoading}/>
        </div>
    )
}

export default DashboardSection