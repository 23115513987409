import React from 'react';
import { makeStyles } from '@material-ui/styles';
//@ts-ignore
import AnimatedNumber from 'animated-number-react';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    info: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    infoInline: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    infoInlineMainContainerWithIcon: {
        justifyContent: 'center',
        alignItems: 'center',
        display:'flex',
        flexDirection:'column'
    },

    infoInlineWithIcon: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
    },

    text: {
        fontSize: 18,
        color: Colors.Text2,
        fontWeight: 500,
        display: 'inline',
    },

    text2: {
        display: 'block',
        color: Colors.Gray5,
        fontWeight: 400,
        width: 12,
        marginLeft: 0,
        height: 2,
        backgroundColor: Colors.Gray3,
        border: 'none',
        opacity: 0.6 + ' !important',
    },
}));

export type AnimatedNumberProps = {
    value: number;
    hideThousands?: boolean;
    className?: string;
    dataTestId?: string;
    dataQa?: string;
    showLongDash?: boolean;
    icon?: any;
    formatValue?: (value: number) => string;
    inLine?: boolean;
    doNotAnimateValue?: boolean;
};

const AnimatedNumberText: React.VFC<AnimatedNumberProps> = ({
    value,
    hideThousands,
    className,
    dataTestId,
    dataQa,
    showLongDash,
    icon,
    formatValue,
    inLine = true,
    doNotAnimateValue = false,
}) => {
    const classes = useStyles();

    const isKNotation = hideThousands && value > 999;

    return (
        <>
            <Fade in={true} timeout={doNotAnimateValue ? 0 : 2000}>
                <div
                    className={classNames(classes.text, className)}
                    data-testid={dataTestId}
                    data-qa={dataQa}
                >
                    {showLongDash ? (
                        <hr
                            data-qa={'show-long-dash'}
                            data-testid={'show-long-dash'}
                            className={classes.text2}
                        />
                    ) : (
                        <>
                            <div


                                className={
                                    inLine && icon ? classes.infoInlineMainContainerWithIcon : inLine ? classes.infoInline : classes.info
                                }
                            >
                                {icon && icon}
                                <div
                                    className={
                                        inLine && icon
                                            ? classes.infoInlineWithIcon
                                            : inLine
                                            ? classes.infoInline
                                            : classes.info
                                    }
                                >
                                    {doNotAnimateValue ? (
                                        <>
                                            {formatValue?.(
                                                isKNotation
                                                    ? value / 1000
                                                    : value,
                                            ) ??
                                                (isKNotation
                                                    ? value / 1000
                                                    : value
                                                ).toFixed(isKNotation ? 1 : 0)}
                                        </>
                                    ) : (
                                        <AnimatedNumber
                                            value={
                                                isKNotation
                                                    ? value / 1000
                                                    : value
                                            }
                                            formatValue={(v: number) => {
                                                return (
                                                    formatValue?.(v) ??
                                                    v.toFixed(
                                                        isKNotation ? 1 : 0,
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                    {isKNotation && <span>k</span>}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Fade>
        </>
    );
};

export default AnimatedNumberText;
