import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../styles/Colors';

export interface SearchNavigatorInterface {
    customStyle?: string;
    onChangeValue: (val: string) => void;
    total?: number;
    matchIndex?: number;
    clickOnUp?: () => void;
    clickOnDown?: () => void;
    forceShow:boolean
}

export const useStyle = makeStyles(() => ({
    searchResultContainer: {
        fontSize: 12,
        color: Colors.LightGraySecondary2,
        lineHeight: '16px',
        marginRight: 6,
    },

    actionContainer: {
        border: 'none',
        borderLeftStyle: 'solid',
        borderColor: Colors.Gray3,
        borderLeftWidth: '1px',
        display: 'flex',
        alignItems: 'center',
    },

    searchNavigatorContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minHeight: 44,
        maxHeight:44,

        '& #search-text-container':{
          width:318,
            height: '34px',
            display: 'flex',
            alignItems: 'center',

            '& > svg': {
                position: 'absolute',
                right: 0,
                zIndex:20,
                opacity:1
            },
        },

        '& #search-text-placeholder': {
            position:'absolute',
            width: 0,
            marginRight: 0,
            backgroundColor: 'transparent',
            opacity: 0,
            transition: 'all 0.3s ease',
            borderRadius: '5px',
            zIndex:5,
            right:0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',

            '& span':{
                transition: 'all 1s ease',
                opacity:0,
                display:'none'

            }

        },

        '& svg:hover + #search-text-placeholder': {
            width: 318,
            backgroundColor: Colors.LightGray2,
            opacity: 1,
            color: Colors.Gray7,
            marginRight: 0,

            '& span':{
                transition: 'all 1s ease',
                opacity:1,
                display:'initial',
                paddingLeft:10

            }
        },
    },

    placeholderContainer: {
        padding: 5,
        paddingRight: 0,
        fontSize: 12,
        position:"relative",
        width:318
    },

    searchContainer: {
        border: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        borderColor: Colors.Gray3,
        padding: 8,
        display: 'flex',
        backgroundColor: Colors.White,
        alignItems: 'center',
        minWidth: 300,
        maxWidth: 300,

        '& input': {
            border: 'none',
            width: '100%',
            flex: 1,
        },
        '& input:focus': {
            border: 'none',
        },
        '& input:focus-visible': {
            outline: 'none',
        },
    },

    blue: {
        '& path': {
            fill: Colors.Link + '!important',
        },
    },

    black: {
        width: 20,
        height: 20,
        '& path': {
            fill: Colors.BlackSurface + '!important',
        },
    },

    active: {
        '& path': {
            fill: Colors.BlackSurface + '!important',
        },
    },

    disabled: {
        '& path': {
            fill: Colors.PlaceHolder + '!important',
        },
    },
}));